import { getCurrentRole } from 'helpers/Utils';
import { CHANGE_ROLE } from '../contants';

const INIT_STATE = {
  role: getCurrentRole(),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_ROLE:
      return { ...state, role: action.payload };

    default:
      return { ...state };
  }
};
