import axios from 'axios';
import {serviceApiBaseUrl, authApiBaseUrl, firebaseConfig, currentUser} from 'constants/defaultValues';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { NotificationManager } from 'components/common/react-notifications';

const qs = require('qs');



const setToken = (token) => {
  console.log(token)
  if (token) {
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
  return 1;
};

// const signInWithEmailAndPassword=(email,password)=> new Promise((resolve,reject)=>{
//   // const cu
//   axios({
//     method: 'post',
//     url: 'https://auth.ainnotate.com/auth/realms/aidac/protocol/openid-connect/token',
//     headers: { 
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     data : qs.stringify({
//       'username': email,
//       'password': password,
//       'client_id': 'web_app',
//       'client_secret': 'web_app',
//       'grant_type': 'password' 
//     })
//   })
//   .then(function (loginResponse) {
//     return loginResponse.data
//   })
//   .then(loginData => {
//     sessionStorage.setItem('access-token', loginData.access_token)
// 		sessionStorage.setItem('refresh-token', loginData.refresh_token)
//     const abs= {
//       ...currentUser,
//       date: Date.now(),
//       tokenLastSeen: Date.now(),
//       refreshTokenLastSeen: Date.now(),
//       accessToken: loginData.access_token,
//       refreshToken: loginData.refresh_token,
//     }
//     resolve(abs)
//   })
// })


const signInWithEmailAndPassword=(email,password)=> new Promise((resolve,reject)=>{ 
    axios({
      method: 'post',
      url: `${sessionStorage.getItem('@authURL')}/realms/aidac/protocol/openid-connect/token`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : qs.stringify({
      'username': email,
      'password': password,
      'client_id': 'web_app',
      'client_secret': 'web_app',
      'grant_type': 'password' 
      })
    })
    .then(function (loginResponse) {
      console.log("res from login - ",loginResponse)
		return loginResponse.data
	})
	.then(loginData => {
		axios({
			method: 'get',
			url: `${sessionStorage.getItem('@serviceURL')}/api/account`,
			headers: {
				Authorization: `Bearer ${loginData.access_token}`
			}
    })
    .then(accountResponse => {
        return accountResponse.data
    })
    .then(accountData => {
			axios({
				method: 'get',
				url: `${sessionStorage.getItem('@serviceURL')}/services/ainnotateservice/api/aidas-users/${accountData.aidasId}`,
				// url: `${sessionStorage.getItem('@serviceURL')}/services/ainnotateservice/api/aidas-users/3`,
				headers: {
				  'Authorization': `Bearer ${loginData.access_token}`,
				  'Content-Type': 'application/json', 
				  'Cookie': 'XSRF-TOKEN=31172cb6-dd21-4453-9bfa-bd5beec9d775'
				}
			})
			.then((userResponse) => {
				return userResponse.data;
			})
			.then(async (userData) => {
				const abs= {
					id: userData.id,
					title: `${userData.firstName} ${userData.lastName}`,
					firstName: userData.firstName,
					lastName: userData.lastName,
					isSubscribed:true,
					role: accountData.currentRole.replace('[', "").replace(']', ""),
					img: `${sessionStorage.getItem('@serviceURL')}/favicon.ico`,
					date: Date.now(),
					tokenLastSeen: Date.now(),
					refreshTokenLastSeen: Date.now(),
					accessToken: loginData.access_token,
					refreshToken: loginData.refresh_token,
					access: accountData.authorities
				}
				resolve(abs)
				sessionStorage.setItem('access-token', loginData.access_token)
				sessionStorage.setItem('refresh-token', loginData.refresh_token)
				NotificationManager.success('Welcome to Aidas', `Login successful`, 3000, null, null, '');
			})
			.catch(error => {	
				console.log("Error while login => ", error)
				NotificationManager.error(null, 'Error Logging In', 3000, null, null, '')
				reject(error)
			})
    })
		.catch(error => {
			console.log("Error => ", error)
			NotificationManager.error(null, 'Error logging in', 3000, null, null, '')
	  })
	})
	.catch(function (error) {
		console.log(error);
		NotificationManager.error(null, `Error Logging In`, 3000, null, null, '');
		reject(error)
  })
});

const createUserWithEmailAndPassword=(email,password)=> new Promise((resolve,reject)=>{

    const dataForApi = qs.stringify({
      "email": email,
      "password": password,
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}auth/createUserWithEmailAndPassword`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      resolve(response.data)
    })
    .catch(function (error) {
      console.log(error);
      reject(error)
    });
    
})

const createUserWithGoogle=()=> new Promise((resolve,reject)=>{
  
  resolve()
  window.location=(`${apiBaseUrl}auth/google`)

  // const config = {
  //   method: 'get',
  //   url: `${apiBaseUrl}auth/google`,
  // };
  
  // axios(config)
  // .then(function (response) {
  //   console.log(JSON.stringify(response.data));
  //   resolve(response.data)
  // })
  // .catch(function (error) {
  //   console.log(error);
  //   reject(error)
  // });
  
})
const createUserWithGoogleCallback= ()=> new Promise((resolve,reject)=>{
  const token=sessionStorage.getItem('token');

const data={
    success: true,
    token: sessionStorage.getItem('token'),
    user: {
      id: sessionStorage.getItem('id'),
      firstName: sessionStorage.getItem('fn'),
      lastName: sessionStorage.getItem('ln'),
      email: sessionStorage.getItem('em'),
      role: sessionStorage.getItem('rl'),
      avatar: sessionStorage.getItem('img')
    }
  }
  console.log(data)

  resolve(data)

// axios.get(`${apiBaseUrl}auth/google/login`)
//  .then(function (response) {
//     console.log(JSON.stringify(response.data));
//     resolve(response.data)
//   })
//   .catch(function (error) {
//     console.log(error);
//     reject(error)
//   });


  
})


const createUserWithFacebook=()=> new Promise((resolve,reject)=>{
  
  resolve()
  window.location=(`${apiBaseUrl}auth/facebook`)

  // const config = {
  //   method: 'get',
  //   url: `${apiBaseUrl}auth/Facebook`,
  // };
  
  // axios(config)
  // .then(function (response) {
  //   console.log(JSON.stringify(response.data));
  //   resolve(response.data)
  // })
  // .catch(function (error) {
  //   console.log(error);
  //   reject(error)
  // });
  
})
const createUserWithFacebookCallback= ()=> new Promise((resolve,reject)=>{
  const token=sessionStorage.getItem('token');

const data={
    success: true,
    token: sessionStorage.getItem('token'),
    user: {
      id: sessionStorage.getItem('id'),
      firstName: sessionStorage.getItem('fn'),
      lastName: sessionStorage.getItem('ln'),
      email: sessionStorage.getItem('em'),
      role: sessionStorage.getItem('rl'),
      avatar: sessionStorage.getItem('img')
    }
  }
  console.log(data)

  resolve(data)

// axios.get(`${apiBaseUrl}auth/Facebook/login`)
//  .then(function (response) {
//     console.log(JSON.stringify(response.data));
//     resolve(response.data)
//   })
//   .catch(function (error) {
//     console.log(error);
//     reject(error)
//   });


  
})




const sendPasswordResetEmail=(email)=>{

    const dataForApi = qs.stringify({
      'email': email,
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}auth/sendPasswordResetEmail`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
    
}

const confirmPasswordReset=(resetPasswordCode,newPassword)=>{

    const dataForApi = qs.stringify({
      'resetPasswordCode': resetPasswordCode,
      'newPassword': newPassword,
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}auth/confirmPasswordReset`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
throw error
    });
    
}

const signOut=()=>new Promise((resolve,reject)=>{

  console.log("sign out forntend")

    const dataForApi = qs.stringify({
      'signOut': "ok",
    });
    const config = {
      method: 'post',
      url: `${apiBaseUrl}/auth/signOut`,
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : dataForApi
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      resolve(response.data)
    })
    .catch(function (error) {
      console.log(error);
      reject(error)
    });
    
})



const auth={signInWithEmailAndPassword,createUserWithEmailAndPassword,sendPasswordResetEmail,confirmPasswordReset,signOut,createUserWithGoogle,createUserWithGoogleCallback,createUserWithFacebook,createUserWithFacebookCallback}

 export { auth, setToken };
