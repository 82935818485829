import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'helpers/TeceadsAuth';
import { adminRoot, currentUser } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  REGISTER_USER,
  REGISTER_USER_WITH_GOOGLE,
  REGISTER_USER_WITH_GOOGLE_CALLBACK,
  REGISTER_USER_WITH_FACEBOOK,
  REGISTER_USER_WITH_FACEBOOK_CALLBACK,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';


export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    console.log("loginUser---->", loginUser)
    if (!loginUser.message) {

      console.log("loginUser -> ",loginUser)
     

      
      const item = { uid: loginUser.id, ...loginUser };
      console.log("item -> ",item)
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
    console.log("loginUser from else -> ",loginUser)

      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    console.log("loginUser from catch -> ",error)

    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email,password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email,password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchRegisterUserWithGoogle() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER_WITH_GOOGLE, registerWithGoogle);
}

const registerUserWithGoogleAsync = async () =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithGoogle()
    .then((user) => user)
    .catch((error) => error);

function* registerWithGoogle({ payload }) {
  const { history } = payload;
  try {
    const registerUserWithGoogle = yield call(
      registerUserWithGoogleAsync
    );
    if (!registerUserWithGoogle.message) {
      const item = { uid: registerUserWithGoogle.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUserWithGoogle.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}



export function* watchRegisterUserWithGoogleCallback() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER_WITH_GOOGLE_CALLBACK, registerWithGoogleCallback);
}

const registerUserWithGoogleCallbackAsync = async () =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithGoogleCallback()
    .then((user) => user)
    .catch((error) => error);

function* registerWithGoogleCallback({ payload }) {
  const { history } = payload;
  try {
    const registerUserWithGoogleCallback = yield call(
      registerUserWithGoogleCallbackAsync
    );
    if (!registerUserWithGoogleCallback.message) {
      // uid: req.user.id,
      // firstName: req.user.firstName,
      // lastName: req.user.lastName,
      // email: req.user.email,
      // role: req.user.role
      console.log("registerUserWithGoogleCallback",registerUserWithGoogleCallback)
      const newUser= {
        id: registerUserWithGoogleCallback.user.uid,
        title: `${registerUserWithGoogleCallback.user.firstName} ${registerUserWithGoogleCallback.user.lastName}`,
        firstName: registerUserWithGoogleCallback.user.firstName,
        lastName: registerUserWithGoogleCallback.user.lastName,
        userRole:registerUserWithGoogleCallback.user.userRole,
        img: registerUserWithGoogleCallback.user.avatar,
        date: Date.now(),
        role: currentUser.role,
        }

      const item = { uid: registerUserWithGoogleCallback.user.uid, ...newUser };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUserWithGoogleCallback.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}


export function* watchRegisterUserWithFacebook() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER_WITH_FACEBOOK, registerWithFacebook);
}

const registerUserWithFacebookAsync = async () =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithFacebook()
    .then((user) => user)
    .catch((error) => error);

function* registerWithFacebook({ payload }) {
  const { history } = payload;
  try {
    const registerUserWithFacebook = yield call(
      registerUserWithFacebookAsync
    );
    if (!registerUserWithFacebook.message) {
      const item = { uid: registerUserWithFacebook.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUserWithFacebook.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}



export function* watchRegisterUserWithFacebookCallback() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER_WITH_FACEBOOK_CALLBACK, registerWithFacebookCallback);
}

const registerUserWithFacebookCallbackAsync = async () =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithFacebookCallback()
    .then((user) => user)
    .catch((error) => error);

function* registerWithFacebookCallback({ payload }) {
  const { history } = payload;
  try {
    const registerUserWithFacebookCallback = yield call(
      registerUserWithFacebookCallbackAsync
    );
    if (!registerUserWithFacebookCallback.message) {
      // uid: req.user.id,
      // firstName: req.user.firstName,
      // lastName: req.user.lastName,
      // email: req.user.email,
      // role: req.user.role
      console.log("registerUserWithFacebookCallback",registerUserWithFacebookCallback)
      const newUser= {
        id: registerUserWithFacebookCallback.user.uid,
        title: `${registerUserWithFacebookCallback.user.firstName} ${registerUserWithFacebookCallback.user.lastName}`,
        firstName: registerUserWithFacebookCallback.user.firstName,
        lastName: registerUserWithFacebookCallback.user.lastName,
        userRole:registerUserWithFacebookCallback.user.userRole,
        img: registerUserWithFacebookCallback.user.avatar,
        date: Date.now(),
        role: currentUser.role,
        }

      const item = { uid: registerUserWithFacebookCallback.user.uid, ...newUser };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUserWithFacebookCallback.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}




export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  // await auth
  //   .signOut()
  //   .then((user) => user)
  //   .catch((error) => error);
  // history.push(adminRoot);
  window.location = "/user/login"
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser({});
  // window.location = "/user/login"
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchRegisterUserWithGoogle),
    fork(watchRegisterUserWithGoogleCallback),
    fork(watchRegisterUserWithFacebook),
    fork(watchRegisterUserWithFacebookCallback),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
