import axios from 'axios';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';

const qs = require('qs')

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (sessionStorage.getItem('direction')) {
      const localValue = sessionStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    sessionStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (sessionStorage.getItem(themeColorStorageKey)) {
      currentColor = sessionStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    sessionStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (sessionStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = sessionStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    sessionStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      sessionStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === sessionStorage.getItem('currentLanguage')
      ).length > 0
        ? sessionStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    sessionStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      sessionStorage.getItem('gogo_current_user') != null
        ? JSON.parse(sessionStorage.getItem('gogo_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const performTokenRefresh = () => {
  // get the current time
  // get the last time when the token was refreshed
  // if the time difference is greater than the access token expiration, go for refresh token implmemtation
  // refresh -> using the refresh token and update the time and tokens
  // if the time is greater than the refresh token expiration, go to the unauthorized page
  console.log("calling token refresh")
  const currentTime = Date.now()
  const currentUserDetails = JSON.parse(sessionStorage.getItem('gogo_current_user'))
  const lastAccessTokenRefreshTime = currentUserDetails.tokenLastSeen
  const timeDiff = currentTime - lastAccessTokenRefreshTime
  let status = false
  console.log("Time diff => ", lastAccessTokenRefreshTime, currentTime, timeDiff)
  if(timeDiff > 300000 && timeDiff < 1800000) {
    axios({
      method: 'post',
      url: 'https://auth.ainnotate.com/realms/aidac/protocol/openid-connect/token',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : qs.stringify({
        'refresh_token': currentUserDetails.refreshToken,
        'client_id': 'web_app',
        'client_secret': 'web_app',
        'grant_type': 'refresh_token' 
       })
    })
    .then(loginResponse => {return loginResponse.data})
    .then(loginData => {
      console.log("login data => ", loginData)
      currentUserDetails.accessToken = loginData.access_token
      currentUserDetails.refreshToken = loginData.refresh_token
      currentUserDetails.tokenLastSeen = currentTime
			currentUserDetails.refreshTokenLastSeen = currentTime
      sessionStorage.setItem('gogo_current_user', JSON.stringify(currentUserDetails))
      status = true
    })
  } else if (timeDiff < 300000){
    status = true
  } else {
    status = false
  }
  console.log("STATUS => ", status)
  return status
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      sessionStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      sessionStorage.clear()
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const setCurrentRole = (role) => {
  try {
    if(role) {
      console.log("current role from action 2util", role)

      const user = JSON.parse(sessionStorage.getItem('gogo_current_user'))
      user.role = role
      sessionStorage.setItem('gogo_current_user', JSON.stringify(user))
    }
  } 
  catch (error) {
    console.log("Error setting current role => ", error)
  }
}

export const getCurrentRole = () => {
  let role = null;
  try {
    const userDetails = JSON.parse(sessionStorage.getItem('gogo_current_user'))
    if(userDetails)
      role = userDetails.role
  }
  catch (error) {
    console.log("Error getting current role => ", error)
  }
  return role
}
