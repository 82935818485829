require('dotenv').config()

export const aidasRoles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_ORG_ADMIN: "ROLE_ORG_ADMIN",
  ROLE_CUSTOMER_ADMIN: "ROLE_CUSTOMER_ADMIN",
  ROLE_VENDOR_ADMIN: "ROLE_VENDOR_ADMIN",
  ROLE_VENDOR_USER: "ROLE_VENDOR_USER",
  ROLE_ORG_QC:"ROLE_ORG_QC",
  ROLE_ADMIN_QC:"ROLE_ADMIN_QC",
  ROLE_VENDOR_QC:"ROLE_VENDOR_QC",
  ROLE_CUSTOMER_QC:"ROLE_CUSTOMER_QC"
};
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/aidas';
export const userRoot = '/app';
export const buyUrl = 'https://haidata.ai';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';


//export const serviceApiBaseUrl = "https://aidas.ainnotate.com";
//export const authApiBaseUrl = "https://auth.ainnotate.com";

//export const serviceApiBaseUrl = "https://aidas-staging.ainnotate.com";
//export const authApiBaseUrl = "https://auth-staging.ainnotate.com";

export const serviceApiBaseUrl = "http://aidas-api.atparui.com:1080";
export const authApiBaseUrl = "http://aidas-auth.atparui.com:1080";

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  firstName: 'Sarah Kortney',
  lastName: 'Sarah Kortney',
  isSubscribed:true,
  userRole:aidasRoles.ROLE_ADMIN,
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:23',
  role: aidasRoles.ROLE_ADMIN,
};

export const defaultUser = {
  id: 1,
  title: 'Sarah Kortney',
  firstName: 'Sarah Kortney',
  lastName: 'Sarah Kortney',
  isSubscribed:true,
  userRole:aidasRoles.ROLE_ADMIN,
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:23',
  role: aidasRoles.ROLE_ADMIN,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.greenteal';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const accessMap = {
  dashboard: [aidasRoles.ROLE_ADMIN, aidasRoles.ROLE_ORG_ADMIN, aidasRoles.ROLE_CUSTOMER_ADMIN, aidasRoles.ROLE_VENDOR_ADMIN],
  organisation: [aidasRoles.ROLE_ADMIN],
  customers: [aidasRoles.ROLE_ADMIN, aidasRoles.ROLE_ORG_ADMIN],
  projects: [aidasRoles.ROLE_ADMIN, aidasRoles.ROLE_ORG_ADMIN, aidasRoles.ROLE_CUSTOMER_ADMIN, aidasRoles.ROLE_VENDOR_ADMIN],
  vendors: [aidasRoles.ROLE_ADMIN, aidasRoles.ROLE_ORG_ADMIN],
  users: [aidasRoles.ROLE_ADMIN, aidasRoles.ROLE_ORG_ADMIN, aidasRoles.ROLE_VENDOR_ADMIN],
  uploads: [],
  // uploads: [aidasRoles.ROLE_ADMIN, aidasRoles.ROLE_ORG_ADMIN, aidasRoles.ROLE_CUSTOMER_ADMIN, aidasRoles.ROLE_VENDOR_ADMIN, aidasRoles.ROLE_VENDOR_USER],
  qc:[aidasRoles.ROLE_ORG_QC, aidasRoles.ROLE_ADMIN_QC, aidasRoles.ROLE_VENDOR_QC, aidasRoles.ROLE_CUSTOMER_QC],
  download:[aidasRoles.ROLE_ADMIN,aidasRoles.ROLE_ORG_ADMIN,aidasRoles.ROLE_CUSTOMER_ADMIN],
  metadata:[aidasRoles.ROLE_ADMIN,aidasRoles.ROLE_ORG_ADMIN,aidasRoles.ROLE_CUSTOMER_ADMIN,aidasRoles.ROLE_VENDOR_ADMIN,aidasRoles.ROLE_VENDOR_USER],
  objects: [aidasRoles.ROLE_ADMIN, aidasRoles.ROLE_ORG_ADMIN, aidasRoles.ROLE_CUSTOMER_ADMIN],
  consentform: [],
  deleteuser: [aidasRoles.ROLE_VENDOR_USER],
}

export const ACCESS_TOKEN_LIFE_IN_MILLIS = 2592000000
export const REFRESH_TOKEN_LIFE_IN_MILLIS = 2592000000

export const countryStates = [
  {
    "name": "Afghanistan",
    "states": [
      "Badakhshan",
      "Badghis",
      "Baghlan",
      "Balkh",
      "Bamyan",
      "Daykundi",
      "Farah",
      "Faryab",
      "Ghazni",
      "Ghōr",
      "Helmand",
      "Herat",
      "Jowzjan",
      "Kabul",
      "Kandahar",
      "Kapisa",
      "Khost",
      "Kunar",
      "Kunduz Province",
      "Laghman",
      "Logar",
      "Nangarhar",
      "Nimruz",
      "Nuristan",
      "Paktia",
      "Paktika",
      "Panjshir",
      "Parwan",
      "Samangan",
      "Sar-e Pol",
      "Takhar",
      "Urozgan",
      "Zabul"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg",
    "dial": "+93",
    "code": "AF"
  },
  {
    "name": "Albania",
    "states": [
      "Berat County",
      "Berat District",
      "Bulqizë District",
      "Delvinë District",
      "Devoll District",
      "Dibër County",
      "Dibër District",
      "Durrës County",
      "Durrës District",
      "Elbasan County",
      "Fier County",
      "Fier District",
      "Gjirokastër County",
      "Gjirokastër District",
      "Gramsh District",
      "Has District",
      "Kavajë District",
      "Kolonjë District",
      "Korçë County",
      "Korçë District",
      "Krujë District",
      "Kuçovë District",
      "Kukës County",
      "Kukës District",
      "Kurbin District",
      "Lezhë County",
      "Lezhë District",
      "Librazhd District",
      "Lushnjë District",
      "Malësi e Madhe District",
      "Mallakastër District",
      "Mat District",
      "Mirditë District",
      "Peqin District",
      "Përmet District",
      "Pogradec District",
      "Pukë District",
      "Sarandë District",
      "Shkodër County",
      "Shkodër District",
      "Skrapar District",
      "Tepelenë District",
      "Tirana County",
      "Tirana District",
      "Tropojë District",
      "Vlorë County",
      "Vlorë District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg",
    "dial": "+355",
    "code": "AL"
  },
  {
    "name": "Algeria",
    "states": [
      "Adrar Province",
      "Aïn Defla Province",
      "Aïn Témouchent Province",
      "Algiers Province",
      "Annaba Province",
      "Batna Province",
      "Béchar Province",
      "Béjaïa Province",
      "Biskra",
      "Blida Province",
      "Bordj Bou Arréridj Province",
      "Bouïra Province",
      "Boumerdès Province",
      "Chlef Province",
      "Constantine Province",
      "Djelfa Province",
      "El Bayadh Province",
      "El Oued Province",
      "El Tarf Province",
      "Ghardaïa Province",
      "Guelma Province",
      "Illizi Province",
      "Jijel Province",
      "Khenchela Province",
      "Laghouat Province",
      "M'Sila Province",
      "Mascara Province",
      "Médéa Province",
      "Mila Province",
      "Mostaganem Province",
      "Naama Province",
      "Oran Province",
      "Ouargla Province",
      "Oum El Bouaghi Province",
      "Relizane Province",
      "Saïda Province",
      "Sétif Province",
      "Skikda Province",
      "Souk Ahras Province",
      "Tamanghasset Province",
      "Tébessa Province",
      "Tiaret Province",
      "Tindouf Province",
      "Tipasa Province",
      "Tissemsilt Province",
      "Tizi Ouzou Province",
      "Tlemcen Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg",
    "dial": "+213",
    "code": "DZ"
  },
  {
    "name": "Andorra",
    "states": [
      "Andorra la Vella",
      "Canillo",
      "Encamp",
      "Escaldes-Engordany",
      "La Massana",
      "Ordino",
      "Sant Julià de Lòria"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg",
    "dial": "+376",
    "code": "AD"
  },
  {
    "name": "Angola",
    "states": [
      "Bengo Province",
      "Benguela Province",
      "Bié Province",
      "Cabinda Province",
      "Cuando Cubango Province",
      "Cuanza Norte Province",
      "Cuanza Sul",
      "Cunene Province",
      "Huambo Province",
      "Huíla Province",
      "Luanda Province",
      "Lunda Norte Province",
      "Lunda Sul Province",
      "Malanje Province",
      "Moxico Province",
      "Uíge Province",
      "Zaire Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg",
    "dial": "+244",
    "code": "AO"
  },
  {
    "name": "Antigua And Barbuda",
    "states": [
      "Barbuda",
      "Redonda",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Mary Parish",
      "Saint Paul Parish",
      "Saint Peter Parish",
      "Saint Philip Parish"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg",
    "dial": "+1268",
    "code": "AG"
  },
  {
    "name": "Argentina",
    "states": [
      "Autonomous City Of Buenos Aires",
      "Buenos Aires Province",
      "Catamarca Province",
      "Chaco Province",
      "Chubut Province",
      "Córdoba Province",
      "Corrientes",
      "Entre Ríos Province",
      "Formosa Province",
      "Jujuy Province",
      "La Pampa",
      "La Rioja Province",
      "Mendoza",
      "Misiones Province",
      "Neuquén Province",
      "Río Negro Province",
      "Salta Province",
      "San Juan Province",
      "San Luis Province",
      "Santa Cruz Province",
      "Santa Fe Province",
      "Santiago del Estero Province",
      "Tierra del Fuego Province",
      "Tucumán Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg",
    "dial": "+54",
    "code": "AR"
  },
  {
    "name": "Armenia",
    "states": [
      "Aragatsotn Region",
      "Ararat Province",
      "Armavir Region",
      "Gegharkunik Province",
      "Kotayk Region",
      "Lori Region",
      "Shirak Region",
      "Syunik Province",
      "Tavush Region",
      "Vayots Dzor Region",
      "Yerevan"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg",
    "dial": "+374",
    "code": "AM"
  },
  {
    "name": "Australia",
    "states": [
      "Australian Capital Territory",
      "New South Wales",
      "Northern Territory",
      "Queensland",
      "South Australia",
      "Tasmania",
      "Victoria",
      "Western Australia"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
    "dial": "+61",
    "code": "AU"
  },
  {
    "name": "Austria",
    "states": [
      "Burgenland",
      "Carinthia",
      "Lower Austria",
      "Salzburg",
      "Styria",
      "Tyrol",
      "Upper Austria",
      "Vienna",
      "Vorarlberg"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg",
    "dial": "+43",
    "code": "AT"
  },
  {
    "name": "Azerbaijan",
    "states": [
      "Absheron District",
      "Agdam District",
      "Agdash District",
      "Aghjabadi District",
      "Agstafa District",
      "Agsu District",
      "Astara District",
      "Babek District",
      "Baku",
      "Balakan District",
      "Barda District",
      "Beylagan District",
      "Bilasuvar District",
      "Dashkasan District",
      "Fizuli District",
      "Ganja",
      "Gədəbəy",
      "Gobustan District",
      "Goranboy District",
      "Goychay",
      "Goygol District",
      "Hajigabul District",
      "Imishli District",
      "Ismailli District",
      "Jabrayil District",
      "Jalilabad District",
      "Julfa District",
      "Kalbajar District",
      "Kangarli District",
      "Khachmaz District",
      "Khizi District",
      "Khojali District",
      "Kurdamir District",
      "Lachin District",
      "Lankaran",
      "Lankaran District",
      "Lerik District",
      "Martuni",
      "Masally District",
      "Mingachevir",
      "Nakhchivan Autonomous Republic",
      "Neftchala District",
      "Oghuz District",
      "Ordubad District",
      "Qabala District",
      "Qakh District",
      "Qazakh District",
      "Quba District",
      "Qubadli District",
      "Qusar District",
      "Saatly District",
      "Sabirabad District",
      "Sadarak District",
      "Salyan District",
      "Samukh District",
      "Shabran District",
      "Shahbuz District",
      "Shaki",
      "Shaki District",
      "Shamakhi District",
      "Shamkir District",
      "Sharur District",
      "Shirvan",
      "Shusha District",
      "Siazan District",
      "Sumqayit",
      "Tartar District",
      "Tovuz District",
      "Ujar District",
      "Yardymli District",
      "Yevlakh",
      "Yevlakh District",
      "Zangilan District",
      "Zaqatala District",
      "Zardab District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg",
    "dial": "+994",
    "code": "AZ"
  },
  {
    "name": "Bahamas The",
    "states": [
      "Acklins",
      "Acklins and Crooked Islands",
      "Berry Islands",
      "Bimini",
      "Black Point",
      "Cat Island",
      "Central Abaco",
      "Central Andros",
      "Central Eleuthera",
      "Crooked Island",
      "East Grand Bahama",
      "Exuma",
      "Freeport",
      "Fresh Creek",
      "Governor's Harbour",
      "Grand Cay",
      "Green Turtle Cay",
      "Harbour Island",
      "High Rock",
      "Hope Town",
      "Inagua",
      "Kemps Bay",
      "Long Island",
      "Mangrove Cay",
      "Marsh Harbour",
      "Mayaguana District",
      "Nichollstown and Berry Islands",
      "North Abaco",
      "North Andros",
      "North Eleuthera",
      "Ragged Island",
      "Rock Sound",
      "Rum Cay District",
      "San Salvador and Rum Cay",
      "San Salvador Island",
      "Sandy Point",
      "South Abaco",
      "South Andros",
      "South Eleuthera",
      "Spanish Wells",
      "West Grand Bahama"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg",
    "dial": "+1 242",
    "code": "BS"
  },
  {
    "name": "Bahrain",
    "states": [
      "Capital Governorate",
      "Central Governorate",
      "Muharraq Governorate",
      "Northern Governorate",
      "Southern Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg",
    "dial": "+973",
    "code": "BH"
  },
  {
    "name": "Bangladesh",
    "states": [
      "Bagerhat District",
      "Bahadia",
      "Bandarban District",
      "Barguna District",
      "Barisal District",
      "Barisal Division",
      "Bhola District",
      "Bogra District",
      "Brahmanbaria District",
      "Chandpur District",
      "Chapai Nawabganj District",
      "Chittagong District",
      "Chittagong Division",
      "Chuadanga District",
      "Comilla District",
      "Cox's Bazar District",
      "Dhaka District",
      "Dhaka Division",
      "Dinajpur District",
      "Faridpur District",
      "Feni District",
      "Gaibandha District",
      "Gazipur District",
      "Gopalganj District",
      "Habiganj District",
      "Jamalpur District",
      "Jessore District",
      "Jhalokati District",
      "Jhenaidah District",
      "Joypurhat District",
      "Khagrachari District",
      "Khulna District",
      "Khulna Division",
      "Kishoreganj District",
      "Kurigram District",
      "Kushtia District",
      "Lakshmipur District",
      "Lalmonirhat District",
      "Madaripur District",
      "Meherpur District",
      "Moulvibazar District",
      "Munshiganj District",
      "Mymensingh District",
      "Mymensingh Division",
      "Naogaon District",
      "Narail District",
      "Narayanganj District",
      "Natore District",
      "Netrokona District",
      "Nilphamari District",
      "Noakhali District",
      "Pabna District",
      "Panchagarh District",
      "Patuakhali District",
      "Pirojpur District",
      "Rajbari District",
      "Rajshahi District",
      "Rajshahi Division",
      "Rangamati Hill District",
      "Rangpur District",
      "Rangpur Division",
      "Satkhira District",
      "Shariatpur District",
      "Sherpur District",
      "Sirajganj District",
      "Sunamganj District",
      "Sylhet District",
      "Sylhet Division",
      "Tangail District",
      "Thakurgaon District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg",
    "dial": "+880",
    "code": "BD"
  },
  {
    "name": "Barbados",
    "states": [
      "Christ Church",
      "Saint Andrew",
      "Saint George",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Lucy",
      "Saint Michael",
      "Saint Peter",
      "Saint Philip",
      "Saint Thomas"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg",
    "dial": "+1 246",
    "code": "BB"
  },
  {
    "name": "Belarus",
    "states": [
      "Brest Region",
      "Gomel Region",
      "Grodno Region",
      "Minsk",
      "Minsk Region",
      "Mogilev Region",
      "Vitebsk Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg",
    "dial": "+375",
    "code": "BY"
  },
  {
    "name": "Belgium",
    "states": [
      "Antwerp",
      "Brussels-Capital Region",
      "East Flanders",
      "Flanders",
      "Flemish Brabant",
      "Hainaut",
      "Liège",
      "Limburg",
      "Luxembourg",
      "Namur",
      "Wallonia",
      "Walloon Brabant",
      "West Flanders"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg",
    "dial": "+32",
    "code": "BE"
  },
  {
    "name": "Belize",
    "states": [
      "Belize District",
      "Cayo District",
      "Corozal District",
      "Orange Walk District",
      "Stann Creek District",
      "Toledo District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg",
    "dial": "+501",
    "code": "BZ"
  },
  {
    "name": "Benin",
    "states": [
      "Alibori Department",
      "Atakora Department",
      "Atlantique Department",
      "Borgou Department",
      "Collines Department",
      "Donga Department",
      "Kouffo Department",
      "Littoral Department",
      "Mono Department",
      "Ouémé Department",
      "Plateau Department",
      "Zou Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg",
    "dial": "+229",
    "code": "BJ"
  },
  {
    "name": "Bermuda",
    "states": [
      "Devonshire Parish",
      "Hamilton Municipality",
      "Hamilton Parish",
      "Paget Parish",
      "Pembroke Parish",
      "Saint George's Municipality",
      "Saint George's Parish",
      "Sandys Parish",
      "Smith's Parish,",
      "Southampton Parish",
      "Warwick Parish"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg",
    "dial": "+1 441",
    "code": "BM"
  },
  {
    "name": "Bhutan",
    "states": [
      "Bumthang District",
      "Chukha District",
      "Dagana District",
      "Gasa District",
      "Haa District",
      "Lhuntse District",
      "Mongar District",
      "Paro District",
      "Pemagatshel District",
      "Punakha District",
      "Samdrup Jongkhar District",
      "Samtse District",
      "Sarpang District",
      "Thimphu District",
      "Trashigang District",
      "Trongsa District",
      "Tsirang District",
      "Wangdue Phodrang District",
      "Zhemgang District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg",
    "dial": "+975",
    "code": "BT"
  },
  {
    "name": "Bolivia",
    "states": [
      "Beni Department",
      "Chuquisaca Department",
      "Cochabamba Department",
      "La Paz Department",
      "Oruro Department",
      "Pando Department",
      "Potosí Department",
      "Santa Cruz Department",
      "Tarija Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg",
    "dial": "+591",
    "code": "BO"
  },
  {
    "name": "Bosnia and Herzegovina",
    "states": [
      "Bosnian Podrinje Canton",
      "Brčko District",
      "Canton 10",
      "Central Bosnia Canton",
      "Federation of Bosnia and Herzegovina",
      "Herzegovina-Neretva Canton",
      "Posavina Canton",
      "Republika Srpska",
      "Sarajevo Canton",
      "Tuzla Canton",
      "Una-Sana Canton",
      "West Herzegovina Canton",
      "Zenica-Doboj Canton"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg",
    "dial": "+387",
    "code": "BA"
  },
  {
    "name": "Botswana",
    "states": [
      "Central District",
      "Ghanzi District",
      "Kgalagadi District",
      "Kgatleng District",
      "Kweneng District",
      "Ngamiland",
      "North-East District",
      "North-West District",
      "South-East District",
      "Southern District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg",
    "dial": "+267",
    "code": "BW"
  },
  {
    "name": "Brazil",
    "states": [
      "Acre",
      "Alagoas",
      "Amapá",
      "Amazonas",
      "Bahia",
      "Ceará",
      "Espírito Santo",
      "Federal District",
      "Goiás",
      "Maranhão",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Pará",
      "Paraíba",
      "Paraná",
      "Pernambuco",
      "Piauí",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rondônia",
      "Roraima",
      "Santa Catarina",
      "São Paulo",
      "Sergipe",
      "Tocantins"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg",
    "dial": "+55",
    "code": "BR"
  },
  {
    "name": "Brunei",
    "states": [
      "Belait District",
      "Brunei-Muara District",
      "Temburong District",
      "Tutong District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg",
    "dial": "+673",
    "code": "BN"
  },
  {
    "name": "Bulgaria",
    "states": [
      "Blagoevgrad Province",
      "Burgas Province",
      "Dobrich Province",
      "Gabrovo Province",
      "Haskovo Province",
      "Kardzhali Province",
      "Kyustendil Province",
      "Lovech Province",
      "Montana Province",
      "Pazardzhik Province",
      "Pernik Province",
      "Pleven Province",
      "Plovdiv Province",
      "Razgrad Province",
      "Ruse Province",
      "Silistra Province",
      "Sliven Province",
      "Smolyan Province",
      "Sofia City Province",
      "Sofia Province",
      "Stara Zagora Province",
      "Targovishte Province",
      "Varna Province",
      "Veliko Tarnovo Province",
      "Vidin Province",
      "Vratsa Province",
      "Yambol Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg",
    "dial": "+359",
    "code": "BG"
  },
  {
    "name": "Burkina Faso",
    "states": [
      "Balé Province",
      "Bam Province",
      "Banwa Province",
      "Bazèga Province",
      "Boucle du Mouhoun Region",
      "Bougouriba Province",
      "Boulgou",
      "Cascades Region",
      "Centre",
      "Centre-Est Region",
      "Centre-Nord Region",
      "Centre-Ouest Region",
      "Centre-Sud Region",
      "Comoé Province",
      "Est Region",
      "Ganzourgou Province",
      "Gnagna Province",
      "Gourma Province",
      "Hauts-Bassins Region",
      "Houet Province",
      "Ioba Province",
      "Kadiogo Province",
      "Kénédougou Province",
      "Komondjari Province",
      "Kompienga Province",
      "Kossi Province",
      "Koulpélogo Province",
      "Kouritenga Province",
      "Kourwéogo Province",
      "Léraba Province",
      "Loroum Province",
      "Mouhoun",
      "Nahouri Province",
      "Namentenga Province",
      "Nayala Province",
      "Nord Region, Burkina Faso",
      "Noumbiel Province",
      "Oubritenga Province",
      "Oudalan Province",
      "Passoré Province",
      "Plateau-Central Region",
      "Poni Province",
      "Sahel Region",
      "Sanguié Province",
      "Sanmatenga Province",
      "Séno Province",
      "Sissili Province",
      "Soum Province",
      "Sourou Province",
      "Sud-Ouest Region",
      "Tapoa Province",
      "Tuy Province",
      "Yagha Province",
      "Yatenga Province",
      "Ziro Province",
      "Zondoma Province",
      "Zoundwéogo Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg",
    "dial": "+226",
    "code": "BF"
  },
  {
    "name": "Burundi",
    "states": [
      "Bubanza Province",
      "Bujumbura Mairie Province",
      "Bujumbura Rural Province",
      "Bururi Province",
      "Cankuzo Province",
      "Cibitoke Province",
      "Gitega Province",
      "Karuzi Province",
      "Kayanza Province",
      "Kirundo Province",
      "Makamba Province",
      "Muramvya Province",
      "Muyinga Province",
      "Mwaro Province",
      "Ngozi Province",
      "Rumonge Province",
      "Rutana Province",
      "Ruyigi Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg",
    "dial": "+257",
    "code": "BI"
  },
  {
    "name": "Cambodia",
    "states": [
      "Banteay Meanchey Province",
      "Battambang Province",
      "Kampong Cham Province",
      "Kampong Chhnang Province",
      "Kampong Speu Province",
      "Kampot Province",
      "Kandal Province",
      "Kep Province",
      "Koh Kong Province",
      "Kratié Province",
      "Mondulkiri Province",
      "Oddar Meanchey Province",
      "Pailin Province",
      "Phnom Penh",
      "Preah Vihear Province",
      "Prey Veng Province",
      "Pursat Province",
      "Ratanakiri Province",
      "Siem Reap Province",
      "Sihanoukville Province",
      "Stung Treng Province",
      "Svay Rieng Province",
      "Takéo Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg",
    "dial": "+855",
    "code": "KH"
  },
  {
    "name": "Cameroon",
    "states": [
      "Adamawa",
      "Centre",
      "East",
      "Far North",
      "Littoral",
      "North",
      "Northwest",
      "South",
      "Southwest",
      "West"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg",
    "dial": "+237",
    "code": "CM"
  },
  {
    "name": "Canada",
    "states": [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
    "dial": "+1",
    "code": "CA"
  },
  {
    "name": "Cape Verde",
    "states": [
      "Barlavento Islands",
      "Boa Vista",
      "Brava",
      "Maio Municipality",
      "Mosteiros",
      "Paul",
      "Porto Novo",
      "Praia",
      "Ribeira Brava Municipality",
      "Ribeira Grande",
      "Ribeira Grande de Santiago",
      "Sal",
      "Santa Catarina",
      "Santa Catarina do Fogo",
      "Santa Cruz",
      "São Domingos",
      "São Filipe",
      "São Lourenço dos Órgãos",
      "São Miguel",
      "São Vicente",
      "Sotavento Islands",
      "Tarrafal",
      "Tarrafal de São Nicolau"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg",
    "dial": "+238",
    "code": "CV"
  },
  {
    "name": "Central African Republic",
    "states": [
      "Bamingui-Bangoran Prefecture",
      "Bangui",
      "Basse-Kotto Prefecture",
      "Haut-Mbomou Prefecture",
      "Haute-Kotto Prefecture",
      "Kémo Prefecture",
      "Lobaye Prefecture",
      "Mambéré-Kadéï",
      "Mbomou Prefecture",
      "Nana-Grébizi Economic Prefecture",
      "Nana-Mambéré Prefecture",
      "Ombella-M'Poko Prefecture",
      "Ouaka Prefecture",
      "Ouham Prefecture",
      "Ouham-Pendé Prefecture",
      "Sangha-Mbaéré",
      "Vakaga Prefecture"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg",
    "dial": "+236",
    "code": "CF"
  },
  {
    "name": "Chad",
    "states": [
      "Bahr el Gazel",
      "Batha Region",
      "Borkou",
      "Ennedi Region",
      "Ennedi-Est",
      "Ennedi-Ouest",
      "Guéra Region",
      "Hadjer-Lamis",
      "Kanem Region",
      "Lac Region",
      "Logone Occidental Region",
      "Logone Oriental Region",
      "Mandoul Region",
      "Mayo-Kebbi Est Region",
      "Mayo-Kebbi Ouest Region",
      "Moyen-Chari Region",
      "N'Djamena",
      "Ouaddaï Region",
      "Salamat Region",
      "Sila Region",
      "Tandjilé Region",
      "Tibesti Region",
      "Wadi Fira Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg",
    "dial": "+235",
    "code": "TD"
  },
  {
    "name": "Chile",
    "states": [
      "Antofagasta Region",
      "Araucanía Region",
      "Arica y Parinacota Region",
      "Atacama Region",
      "Aysén Region",
      "Bío Bío Region",
      "Coquimbo Region",
      "Los Lagos Region",
      "Los Ríos Region",
      "Magellan and the Chilean Antarctic Region",
      "Maule Region",
      "Ñuble Region",
      "O'Higgins",
      "Santiago Metropolitan Region",
      "Tarapacá Region",
      "Valparaíso"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg",
    "dial": "+56",
    "code": "CL"
  },
  {
    "name": "China",
    "states": [
      "Anhui",
      "Beijing",
      "Chongqing",
      "Fujian",
      "Gansu",
      "Guangdong",
      "Guangxi Zhuang Autonomous Region",
      "Guizhou",
      "Hainan",
      "Hebei",
      "Heilongjiang",
      "Henan",
      "Hong Kong",
      "Hubei",
      "Hunan",
      "Inner Mongolia",
      "Jiangsu",
      "Jiangxi",
      "Jilin",
      "Keelung",
      "Liaoning",
      "Macau",
      "Ningxia Hui Autonomous Region",
      "Qinghai",
      "Shaanxi",
      "Shandong",
      "Shanghai",
      "Shanxi",
      "Sichuan",
      "Taiwan Province, People's Republic of China",
      "Tibet Autonomous Region",
      "Xinjiang",
      "Yunnan",
      "Zhejiang"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg",
    "dial": "+86",
    "code": "CN"
  },
  {
    "name": "Colombia",
    "states": [
      "Amazonas Department",
      "Antioquia Department",
      "Arauca Department",
      "Archipelago of Saint Andréws, Providence and Saint Catalina",
      "Atlántico Department",
      "Bolívar Department",
      "Boyacá Department",
      "Caldas Department",
      "Caquetá Department",
      "Casanare Department",
      "Cauca Department",
      "Cesar Department",
      "Chocó Department",
      "Córdoba Department",
      "Cundinamarca Department",
      "Guainía Department",
      "Guaviare Department",
      "Huila Department",
      "La Guajira Department",
      "Magdalena Department",
      "Meta",
      "Nariño Department",
      "Norte de Santander Department",
      "Putumayo Department",
      "Quindío Department",
      "Risaralda Department",
      "Santander Department",
      "Sucre Department",
      "Tolima Department",
      "Valle del Cauca Department",
      "Vaupés Department",
      "Vichada Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg",
    "dial": "+57",
    "code": "CO"
  },
  {
    "name": "Comoros",
    "states": [
      "Anjouan",
      "Grande Comore",
      "Mohéli"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg",
    "dial": "+269",
    "code": "KM"
  },
  {
    "name": "Congo",
    "states": [
      "Bouenza Department",
      "Brazzaville",
      "Cuvette Department",
      "Cuvette-Ouest Department",
      "Kouilou Department",
      "Lékoumou Department",
      "Likouala Department",
      "Niari Department",
      "Plateaux Department",
      "Pointe-Noire",
      "Pool Department",
      "Sangha Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg",
    "dial": "+242",
    "code": "CG"
  },
  {
    "name": "Congo",
    "states": [
      "Bandundu Province",
      "Bas-Congo province",
      "Bas-Uele",
      "Équateur",
      "Haut-Katanga Province",
      "Haut-Lomami District",
      "Haut-Uele",
      "Ituri Interim Administration",
      "Kasaï District",
      "Kasaï-Occidental",
      "Kasaï-Oriental",
      "Katanga Province",
      "Kinshasa",
      "Kwango District",
      "Kwilu District",
      "Lomami Province",
      "Mai-Ndombe Province",
      "Maniema",
      "Mongala District",
      "Nord-Ubangi District",
      "North Kivu",
      "Orientale Province",
      "Sankuru District",
      "South Kivu",
      "Sud-Ubangi",
      "Tanganyika Province",
      "Tshopo District",
      "Tshuapa District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg",
    "dial": "+243",
    "code": "CD"
  },
  {
    "name": "Costa Rica",
    "states": [
      "Alajuela Province",
      "Guanacaste Province",
      "Heredia Province",
      "Limón Province",
      "Provincia de Cartago",
      "Puntarenas Province",
      "San José Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg",
    "dial": "+506",
    "code": "CR"
  },
  {
    "name": "Ivory Coast",
    "states": [
      "Abidjan",
      "Agnéby",
      "Bafing Region",
      "Bas-Sassandra District",
      "Bas-Sassandra Region",
      "Comoé District",
      "Denguélé District",
      "Denguélé Region",
      "Dix-Huit Montagnes",
      "Fromager",
      "Gôh-Djiboua District",
      "Haut-Sassandra",
      "Lacs District",
      "Lacs Region",
      "Lagunes District",
      "Lagunes region",
      "Marahoué Region",
      "Montagnes District",
      "Moyen-Cavally",
      "Moyen-Comoé",
      "N'zi-Comoé",
      "Sassandra-Marahoué District",
      "Savanes Region",
      "Sud-Bandama",
      "Sud-Comoé",
      "Vallée du Bandama District",
      "Vallée du Bandama Region",
      "Woroba District",
      "Worodougou",
      "Yamoussoukro",
      "Zanzan Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg",
    "dial": "+225",
    "code": "CI"
  },
  {
    "name": "Croatia (Hrvatska)",
    "states": [
      "Bjelovar-Bilogora County",
      "Brod-Posavina County",
      "Dubrovnik-Neretva County",
      "Istria County",
      "Koprivnica-Križevci County",
      "Krapina-Zagorje County",
      "Lika-Senj County",
      "Međimurje County",
      "Osijek-Baranja County",
      "Požega-Slavonia County",
      "Primorje-Gorski Kotar County",
      "Šibenik-Knin County",
      "Sisak-Moslavina County",
      "Split-Dalmatia County",
      "Varaždin County",
      "Virovitica-Podravina County",
      "Vukovar-Syrmia County",
      "Zadar County",
      "Zagreb",
      "Zagreb County"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg",
    "dial": "+385",
    "code": "HR"
  },
  {
    "name": "Cuba",
    "states": [
      "Artemisa Province",
      "Camagüey Province",
      "Ciego de Ávila Province",
      "Cienfuegos Province",
      "Granma Province",
      "Guantánamo Province",
      "Havana Province",
      "Holguín Province",
      "Isla de la Juventud",
      "Las Tunas Province",
      "Matanzas Province",
      "Mayabeque Province",
      "Pinar del Río Province",
      "Sancti Spíritus Province",
      "Santiago de Cuba Province",
      "Villa Clara Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg",
    "dial": "+53",
    "code": "CU"
  },
  {
    "name": "Cyprus",
    "states": [
      "Famagusta District",
      "Kyrenia District",
      "Larnaca District",
      "Limassol District",
      "Nicosia District",
      "Paphos District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg",
    "dial": "+357",
    "code": "CY"
  },
  {
    "name": "Czech Republic",
    "states": [
      "Benešov District",
      "Beroun District",
      "Blansko District",
      "Břeclav District",
      "Brno-City District",
      "Brno-Country District",
      "Bruntál District",
      "Central Bohemian Region",
      "Česká Lípa District",
      "České Budějovice District",
      "Český Krumlov District",
      "Cheb District",
      "Chomutov District",
      "Chrudim District",
      "Děčín District",
      "Domažlice District",
      "Frýdek-Místek District",
      "Havlíčkův Brod District",
      "Hodonín District",
      "Horní Počernice",
      "Hradec Králové District",
      "Hradec Králové Region",
      "Jablonec nad Nisou District",
      "Jeseník District",
      "Jičín District",
      "Jihlava District",
      "Jindřichův Hradec District",
      "Karlovy Vary District",
      "Karlovy Vary Region",
      "Karviná District",
      "Kladno District",
      "Klatovy District",
      "Kolín District",
      "Kroměříž District",
      "Liberec District",
      "Liberec Region",
      "Litoměřice District",
      "Louny District",
      "Mělník District",
      "Mladá Boleslav District",
      "Moravian-Silesian Region",
      "Most District",
      "Náchod District",
      "Nový Jičín District",
      "Nymburk District",
      "Olomouc District",
      "Olomouc Region",
      "Opava District",
      "Ostrava-City District",
      "Pardubice District",
      "Pardubice Region",
      "Pelhřimov District",
      "Písek District",
      "Plzeň Region",
      "Plzeň-City District",
      "Plzeň-North District",
      "Plzeň-South District",
      "Prachatice District",
      "Prague",
      "Prague 1",
      "Prague 10",
      "Prague 11",
      "Prague 12",
      "Prague 13",
      "Prague 14",
      "Prague 15",
      "Prague 16",
      "Prague 2",
      "Prague 21",
      "Prague 3",
      "Prague 4",
      "Prague 5",
      "Prague 6",
      "Prague 7",
      "Prague 8",
      "Prague 9",
      "Prague-East District",
      "Prague-West District",
      "Přerov District",
      "Příbram District",
      "Prostějov District",
      "Rakovník District",
      "Rokycany District",
      "Rychnov nad Kněžnou District",
      "Semily District",
      "Sokolov District",
      "South Bohemian Region",
      "South Moravian Region",
      "Strakonice District",
      "Šumperk District",
      "Svitavy District",
      "Tábor District",
      "Tachov District",
      "Teplice District",
      "Třebíč District",
      "Trutnov District",
      "Uherské Hradiště District",
      "Ústí nad Labem District",
      "Ústí nad Labem Region",
      "Ústí nad Orlicí District",
      "Vsetín District",
      "Vyškov District",
      "Vysočina Region",
      "Žďár nad Sázavou District",
      "Zlín District",
      "Zlín Region",
      "Znojmo District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg",
    "dial": "+420",
    "code": "CZ"
  },
  {
    "name": "Denmark",
    "states": [
      "Capital Region of Denmark",
      "Central Denmark Region",
      "North Denmark Region",
      "Region of Southern Denmark",
      "Region Zealand"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg",
    "dial": "+45",
    "code": "DK"
  },
  {
    "name": "Djibouti",
    "states": [
      "Ali Sabieh Region",
      "Arta Region",
      "Dikhil Region",
      "Djibouti",
      "Obock Region",
      "Tadjourah Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg",
    "dial": "+253",
    "code": "DJ"
  },
  {
    "name": "Dominica",
    "states": [
      "Saint Andrew Parish",
      "Saint David Parish",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Joseph Parish",
      "Saint Luke Parish",
      "Saint Mark Parish",
      "Saint Patrick Parish",
      "Saint Paul Parish",
      "Saint Peter Parish"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg",
    "dial": "+1 767",
    "code": "DM"
  },
  {
    "name": "Dominican Republic",
    "states": [
      "Azua Province",
      "Baoruco Province",
      "Barahona Province",
      "Dajabón Province",
      "Distrito Nacional",
      "Duarte Province",
      "El Seibo Province",
      "Espaillat Province",
      "Hato Mayor Province",
      "Hermanas Mirabal Province",
      "Independencia",
      "La Altagracia Province",
      "La Romana Province",
      "La Vega Province",
      "María Trinidad Sánchez Province",
      "Monseñor Nouel Province",
      "Monte Cristi Province",
      "Monte Plata Province",
      "Pedernales Province",
      "Peravia Province",
      "Puerto Plata Province",
      "Samaná Province",
      "San Cristóbal Province",
      "San José de Ocoa Province",
      "San Juan Province",
      "San Pedro de Macorís",
      "Sánchez Ramírez Province",
      "Santiago Province",
      "Santiago Rodríguez Province",
      "Santo Domingo Province",
      "Valverde Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg",
    "dial": "+1 849",
    "code": "DO"
  },
  {
    "name": "Timor-Leste",
    "states": [
      "Aileu municipality",
      "Ainaro Municipality",
      "Baucau Municipality",
      "Bobonaro Municipality",
      "Cova Lima Municipality",
      "Dili municipality",
      "Ermera District",
      "Lautém Municipality",
      "Liquiçá Municipality",
      "Manatuto District",
      "Manufahi Municipality",
      "Viqueque Municipality"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg",
    "dial": "+670",
    "code": "TL"
  },
  {
    "name": "Ecuador",
    "states": [
      "Azuay Province",
      "Bolívar Province",
      "Cañar Province",
      "Carchi Province",
      "Chimborazo Province",
      "Cotopaxi Province",
      "El Oro Province",
      "Esmeraldas",
      "Galápagos Province",
      "Guayas Province",
      "Imbabura Province",
      "Los Ríos Province",
      "Manabí Province",
      "Morona-Santiago Province",
      "Napo Province",
      "Orellana Province",
      "Pastaza Province",
      "Pichincha Province",
      "Santa Elena Province",
      "Santo Domingo de los Tsáchilas Province",
      "Sucumbíos Province",
      "Tungurahua Province",
      "Zamora-Chinchipe Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg",
    "dial": "+593",
    "code": "EC"
  },
  {
    "name": "Egypt",
    "states": [
      "Alexandria Governorate",
      "Aswan Governorate",
      "Asyut Governorate",
      "Beheira Governorate",
      "Beni Suef Governorate",
      "Cairo Governorate",
      "Dakahlia Governorate",
      "Damietta Governorate",
      "Faiyum Governorate",
      "Gharbia Governorate",
      "Giza Governorate",
      "Ismailia Governorate",
      "Kafr el-Sheikh Governorate",
      "Luxor Governorate",
      "Matrouh Governorate",
      "Minya Governorate",
      "Monufia Governorate",
      "New Valley Governorate",
      "North Sinai Governorate",
      "Port Said Governorate",
      "Qalyubia Governorate",
      "Qena Governorate",
      "Red Sea Governorate",
      "Sohag Governorate",
      "South Sinai Governorate",
      "Suez Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg",
    "dial": "+20",
    "code": "EG"
  },
  {
    "name": "El Salvador",
    "states": [
      "Ahuachapán Department",
      "Cabañas Department",
      "Chalatenango Department",
      "Cuscatlán Department",
      "La Libertad Department",
      "La Paz Department",
      "La Unión Department",
      "Morazán Department",
      "San Miguel Department",
      "San Salvador Department",
      "San Vicente Department",
      "Santa Ana Department",
      "Sonsonate Department",
      "Usulután Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg",
    "dial": "+503",
    "code": "SV"
  },
  {
    "name": "Equatorial Guinea",
    "states": [
      "Annobón Province",
      "Bioko Norte Province",
      "Bioko Sur Province",
      "Centro Sur Province",
      "Insular Region",
      "Kié-Ntem Province",
      "Litoral Province",
      "Río Muni",
      "Wele-Nzas Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg",
    "dial": "+240",
    "code": "GQ"
  },
  {
    "name": "Eritrea",
    "states": [
      "Anseba Region",
      "Debub Region",
      "Gash-Barka Region",
      "Maekel Region",
      "Northern Red Sea Region",
      "Southern Red Sea Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg",
    "dial": "+291",
    "code": "ER"
  },
  {
    "name": "Estonia",
    "states": [
      "Harju County",
      "Hiiu County",
      "Ida-Viru County",
      "Järva County",
      "Jõgeva County",
      "Lääne County",
      "Lääne-Viru County",
      "Pärnu County",
      "Põlva County",
      "Rapla County",
      "Saare County",
      "Tartu County",
      "Valga County",
      "Viljandi County",
      "Võru County"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg",
    "dial": "+372",
    "code": "EE"
  },
  {
    "name": "Ethiopia",
    "states": [
      "Addis Ababa",
      "Afar Region",
      "Amhara Region",
      "Benishangul-Gumuz Region",
      "Dire Dawa",
      "Gambela Region",
      "Harari Region",
      "Oromia Region",
      "Somali Region",
      "Southern Nations, Nationalities, and Peoples' Region",
      "Tigray Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg",
    "dial": "+251",
    "code": "ET"
  },
  {
    "name": "Fiji Islands",
    "states": [
      "Ba",
      "Bua",
      "Cakaudrove",
      "Central Division",
      "Eastern Division",
      "Kadavu",
      "Lau",
      "Lomaiviti",
      "Macuata",
      "Nadroga-Navosa",
      "Naitasiri",
      "Namosi",
      "Northern Division",
      "Ra",
      "Rewa",
      "Rotuma",
      "Serua",
      "Tailevu",
      "Western Division"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg",
    "dial": "+679",
    "code": "FJ"
  },
  {
    "name": "Finland",
    "states": [
      "Åland Islands",
      "Central Finland",
      "Central Ostrobothnia",
      "Eastern Finland Province",
      "Finland Proper",
      "Kainuu",
      "Kymenlaakso",
      "Lapland",
      "North Karelia",
      "Northern Ostrobothnia",
      "Northern Savonia",
      "Ostrobothnia",
      "Oulu Province",
      "Päijänne Tavastia",
      "Pirkanmaa",
      "Satakunta",
      "South Karelia",
      "Southern Ostrobothnia",
      "Southern Savonia",
      "Tavastia Proper",
      "Uusimaa"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg",
    "dial": "+358",
    "code": "FI"
  },
  {
    "name": "France",
    "states": [
      "Alo",
      "Alsace",
      "Aquitaine",
      "Auvergne",
      "Auvergne-Rhône-Alpes",
      "Bourgogne-Franche-Comté",
      "Brittany",
      "Burgundy",
      "Centre-Val de Loire",
      "Champagne-Ardenne",
      "Corsica",
      "Franche-Comté",
      "French Guiana",
      "French Polynesia",
      "Grand Est",
      "Guadeloupe",
      "Hauts-de-France",
      "Île-de-France",
      "Languedoc-Roussillon",
      "Limousin",
      "Lorraine",
      "Lower Normandy",
      "Martinique",
      "Mayotte",
      "Nord-Pas-de-Calais",
      "Normandy",
      "Nouvelle-Aquitaine",
      "Occitania",
      "Paris",
      "Pays de la Loire",
      "Picardy",
      "Poitou-Charentes",
      "Provence-Alpes-Côte d'Azur",
      "Réunion",
      "Rhône-Alpes",
      "Saint Barthélemy",
      "Saint Martin",
      "Saint Pierre and Miquelon",
      "Sigave",
      "Upper Normandy",
      "Uvea",
      "Wallis and Futuna"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg",
    "dial": "+33",
    "code": "FR"
  },
  {
    "name": "Gabon",
    "states": [
      "Estuaire Province",
      "Haut-Ogooué Province",
      "Moyen-Ogooué Province",
      "Ngounié Province",
      "Nyanga Province",
      "Ogooué-Ivindo Province",
      "Ogooué-Lolo Province",
      "Ogooué-Maritime Province",
      "Woleu-Ntem Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg",
    "dial": "+241",
    "code": "GA"
  },
  {
    "name": "Gambia The",
    "states": [
      "Banjul",
      "Central River Division",
      "Lower River Division",
      "North Bank Division",
      "Upper River Division",
      "West Coast Division"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg",
    "dial": "+220",
    "code": "GM"
  },
  {
    "name": "Georgia",
    "states": [
      "Adjara",
      "Autonomous Republic of Abkhazia",
      "Guria",
      "Imereti",
      "Kakheti",
      "Khelvachauri Municipality",
      "Kvemo Kartli",
      "Mtskheta-Mtianeti",
      "Racha-Lechkhumi and Kvemo Svaneti",
      "Samegrelo-Zemo Svaneti",
      "Samtskhe-Javakheti",
      "Senaki Municipality",
      "Shida Kartli",
      "Tbilisi"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg",
    "dial": "+995",
    "code": "GE"
  },
  {
    "name": "Germany",
    "states": [
      "Baden-Württemberg",
      "Bavaria",
      "Berlin",
      "Brandenburg",
      "Bremen",
      "Hamburg",
      "Hesse",
      "Lower Saxony",
      "Mecklenburg-Vorpommern",
      "North Rhine-Westphalia",
      "Rhineland-Palatinate",
      "Saarland",
      "Saxony",
      "Saxony-Anhalt",
      "Schleswig-Holstein",
      "Thuringia"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg",
    "dial": "+49",
    "code": "DE"
  },
  {
    "name": "Ghana",
    "states": [
      "Ashanti Region",
      "Brong-Ahafo Region",
      "Central Region",
      "Eastern Region",
      "Greater Accra Region",
      "Northern Region",
      "Upper East Region",
      "Upper West Region",
      "Volta Region",
      "Western Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg",
    "dial": "+233",
    "code": "GH"
  },
  {
    "name": "Grenada",
    "states": [
      "Carriacou and Petite Martinique",
      "Saint Andrew Parish",
      "Saint David Parish",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Mark Parish",
      "Saint Patrick Parish"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg",
    "dial": "+1 473",
    "code": "GD"
  },
  {
    "name": "Guatemala",
    "states": [
      "Alta Verapaz Department",
      "Baja Verapaz Department",
      "Chimaltenango Department",
      "Chiquimula Department",
      "El Progreso Department",
      "Escuintla Department",
      "Guatemala Department",
      "Huehuetenango Department",
      "Izabal Department",
      "Jalapa Department",
      "Jutiapa Department",
      "Petén Department",
      "Quetzaltenango Department",
      "Quiché Department",
      "Retalhuleu Department",
      "Sacatepéquez Department",
      "San Marcos Department",
      "Santa Rosa Department",
      "Sololá Department",
      "Suchitepéquez Department",
      "Totonicapán Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg",
    "dial": "+502",
    "code": "GT"
  },
  {
    "name": "Guinea",
    "states": [
      "Beyla Prefecture",
      "Boffa Prefecture",
      "Boké Prefecture",
      "Boké Region",
      "Conakry",
      "Coyah Prefecture",
      "Dabola Prefecture",
      "Dalaba Prefecture",
      "Dinguiraye Prefecture",
      "Dubréka Prefecture",
      "Faranah Prefecture",
      "Forécariah Prefecture",
      "Fria Prefecture",
      "Gaoual Prefecture",
      "Guéckédou Prefecture",
      "Kankan Prefecture",
      "Kankan Region",
      "Kérouané Prefecture",
      "Kindia Prefecture",
      "Kindia Region",
      "Kissidougou Prefecture",
      "Koubia Prefecture",
      "Koundara Prefecture",
      "Kouroussa Prefecture",
      "Labé Prefecture",
      "Labé Region",
      "Lélouma Prefecture",
      "Lola Prefecture",
      "Macenta Prefecture",
      "Mali Prefecture",
      "Mamou Prefecture",
      "Mamou Region",
      "Mandiana Prefecture",
      "Nzérékoré Prefecture",
      "Nzérékoré Region",
      "Pita Prefecture",
      "Siguiri Prefecture",
      "Télimélé Prefecture",
      "Tougué Prefecture",
      "Yomou Prefecture"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg",
    "dial": "+224",
    "code": "GN"
  },
  {
    "name": "Guinea-Bissau",
    "states": [
      "Bafatá",
      "Biombo Region",
      "Bolama Region",
      "Cacheu Region",
      "Gabú Region",
      "Leste Province",
      "Norte Province",
      "Oio Region",
      "Quinara Region",
      "Sul Province",
      "Tombali Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg",
    "dial": "+245",
    "code": "GW"
  },
  {
    "name": "Guyana",
    "states": [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerara",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg",
    "dial": "+592",
    "code": "GY"
  },
  {
    "name": "Haiti",
    "states": [
      "Artibonite",
      "Centre",
      "Grand'Anse",
      "Nippes",
      "Nord",
      "Nord-Est",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Est"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg",
    "dial": "+509",
    "code": "HT"
  },
  {
    "name": "Honduras",
    "states": [
      "Atlántida Department",
      "Bay Islands Department",
      "Choluteca Department",
      "Colón Department",
      "Comayagua Department",
      "Copán Department",
      "Cortés Department",
      "El Paraíso Department",
      "Francisco Morazán Department",
      "Gracias a Dios Department",
      "Intibucá Department",
      "La Paz Department",
      "Lempira Department",
      "Ocotepeque Department",
      "Olancho Department",
      "Santa Bárbara Department",
      "Valle Department",
      "Yoro Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg",
    "dial": "+504",
    "code": "HN"
  },
  {
    "name": "Hungary",
    "states": [
      "Bács-Kiskun County",
      "Baranya County",
      "Békés County",
      "Békéscsaba",
      "Borsod-Abaúj-Zemplén County",
      "Budapest",
      "Csongrád County",
      "Debrecen",
      "Dunaújváros",
      "Eger",
      "Érd",
      "Fejér County",
      "Győr",
      "Győr-Moson-Sopron County",
      "Hajdú-Bihar County",
      "Heves County",
      "Hódmezővásárhely",
      "Jász-Nagykun-Szolnok County",
      "Kaposvár",
      "Kecskemét",
      "Miskolc",
      "Nagykanizsa",
      "Nógrád County",
      "Nyíregyháza",
      "Pécs",
      "Pest County",
      "Salgótarján",
      "Somogy County",
      "Sopron",
      "Szabolcs-Szatmár-Bereg County",
      "Szeged",
      "Székesfehérvár",
      "Szekszárd",
      "Szolnok",
      "Szombathely",
      "Tatabánya",
      "Tolna County",
      "Vas County",
      "Veszprém",
      "Veszprém County",
      "Zala County",
      "Zalaegerszeg"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg",
    "dial": "+36",
    "code": "HU"
  },
  {
    "name": "Iceland",
    "states": [
      "Capital Region",
      "Eastern Region",
      "Northeastern Region",
      "Northwestern Region",
      "Southern Peninsula Region",
      "Southern Region",
      "Western Region",
      "Westfjords"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg",
    "dial": "+354",
    "code": "IS"
  },
  {
    "name": "India",
    "states": [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
    "dial": "+91",
    "code": "IN"
  },
  {
    "name": "Indonesia",
    "states": [
      "Aceh",
      "Bali",
      "Bangka Belitung Islands",
      "Banten",
      "Bengkulu",
      "Central Java",
      "Central Kalimantan",
      "Central Sulawesi",
      "East Java",
      "East Kalimantan",
      "East Nusa Tenggara",
      "Gorontalo",
      "Jakarta",
      "Jambi",
      "Kalimantan",
      "Lampung",
      "Lesser Sunda Islands",
      "Maluku",
      "Maluku Islands",
      "North Kalimantan",
      "North Maluku",
      "North Sulawesi",
      "North Sumatra",
      "Papua",
      "Riau",
      "Riau Islands",
      "South Kalimantan",
      "South Sulawesi",
      "South Sumatra",
      "Southeast Sulawesi",
      "Special Region of Yogyakarta",
      "Sulawesi",
      "Sumatra",
      "West Java",
      "West Nusa Tenggara",
      "West Papua",
      "West Sulawesi",
      "West Sumatra"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg",
    "dial": "+62",
    "code": "ID"
  },
  {
    "name": "Iran",
    "states": [
      "Alborz Province",
      "Ardabil Province",
      "Bushehr Province",
      "Chaharmahal and Bakhtiari Province",
      "East Azerbaijan Province",
      "Fars Province",
      "Gilan Province",
      "Golestan Province",
      "Hormozgan Province",
      "Ilam Province",
      "Isfahan Province",
      "Kerman Province",
      "Kermanshah Province",
      "Khuzestan Province",
      "Kohgiluyeh and Boyer-Ahmad Province",
      "Kurdistan Province",
      "Lorestan Province",
      "Markazi Province",
      "Mazandaran Province",
      "North Khorasan Province",
      "Qazvin Province",
      "Qom Province",
      "Razavi Khorasan Province",
      "Semnan Province",
      "Sistan and Baluchestan",
      "South Khorasan Province",
      "Tehran Province",
      "West Azarbaijan Province",
      "Yazd Province",
      "Zanjan Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg",
    "dial": "+98",
    "code": "IR"
  },
  {
    "name": "Iraq",
    "states": [
      "Al Anbar Governorate",
      "Al Muthanna Governorate",
      "Al-Qādisiyyah Governorate",
      "Babylon Governorate",
      "Baghdad Governorate",
      "Basra Governorate",
      "Dhi Qar Governorate",
      "Diyala Governorate",
      "Dohuk Governorate",
      "Erbil Governorate",
      "Karbala Governorate",
      "Kirkuk Governorate",
      "Maysan Governorate",
      "Najaf Governorate",
      "Nineveh Governorate",
      "Saladin Governorate",
      "Sulaymaniyah Governorate",
      "Wasit Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg",
    "dial": "+964",
    "code": "IQ"
  },
  {
    "name": "Ireland",
    "states": [
      "Connacht",
      "County Carlow",
      "County Cavan",
      "County Clare",
      "County Cork",
      "County Donegal",
      "County Dublin",
      "County Galway",
      "County Kerry",
      "County Kildare",
      "County Kilkenny",
      "County Laois",
      "County Limerick",
      "County Longford",
      "County Louth",
      "County Mayo",
      "County Meath",
      "County Monaghan",
      "County Offaly",
      "County Roscommon",
      "County Sligo",
      "County Tipperary",
      "County Waterford",
      "County Westmeath",
      "County Wexford",
      "County Wicklow",
      "Leinster",
      "Munster",
      "Ulster"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg",
    "dial": "+353",
    "code": "IE"
  },
  {
    "name": "Israel",
    "states": [
      "Central District",
      "Haifa District",
      "Jerusalem District",
      "Northern District",
      "Southern District",
      "Tel Aviv District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg",
    "dial": "+972",
    "code": "IL"
  },
  {
    "name": "Italy",
    "states": [
      "Abruzzo",
      "Aosta Valley",
      "Apulia",
      "Basilicata",
      "Benevento Province",
      "Calabria",
      "Campania",
      "Emilia-Romagna",
      "Friuli–Venezia Giulia",
      "Lazio",
      "Libero consorzio comunale di Agrigento",
      "Libero consorzio comunale di Caltanissetta",
      "Libero consorzio comunale di Enna",
      "Libero consorzio comunale di Ragusa",
      "Libero consorzio comunale di Siracusa",
      "Libero consorzio comunale di Trapani",
      "Liguria",
      "Lombardy",
      "Marche",
      "Metropolitan City of Bari",
      "Metropolitan City of Bologna",
      "Metropolitan City of Cagliari",
      "Metropolitan City of Catania",
      "Metropolitan City of Florence",
      "Metropolitan City of Genoa",
      "Metropolitan City of Messina",
      "Metropolitan City of Milan",
      "Metropolitan City of Naples",
      "Metropolitan City of Palermo",
      "Metropolitan City of Reggio Calabria",
      "Metropolitan City of Rome",
      "Metropolitan City of Turin",
      "Metropolitan City of Venice",
      "Molise",
      "Pesaro and Urbino Province",
      "Piedmont",
      "Province of Alessandria",
      "Province of Ancona",
      "Province of Ascoli Piceno",
      "Province of Asti",
      "Province of Avellino",
      "Province of Barletta-Andria-Trani",
      "Province of Belluno",
      "Province of Bergamo",
      "Province of Biella",
      "Province of Brescia",
      "Province of Brindisi",
      "Province of Campobasso",
      "Province of Carbonia-Iglesias",
      "Province of Caserta",
      "Province of Catanzaro",
      "Province of Chieti",
      "Province of Como",
      "Province of Cosenza",
      "Province of Cremona",
      "Province of Crotone",
      "Province of Cuneo",
      "Province of Fermo",
      "Province of Ferrara",
      "Province of Foggia",
      "Province of Forlì-Cesena",
      "Province of Frosinone",
      "Province of Gorizia",
      "Province of Grosseto",
      "Province of Imperia",
      "Province of Isernia",
      "Province of L'Aquila",
      "Province of La Spezia",
      "Province of Latina",
      "Province of Lecce",
      "Province of Lecco",
      "Province of Livorno",
      "Province of Lodi",
      "Province of Lucca",
      "Province of Macerata",
      "Province of Mantua",
      "Province of Massa and Carrara",
      "Province of Matera",
      "Province of Medio Campidano",
      "Province of Modena",
      "Province of Monza and Brianza",
      "Province of Novara",
      "Province of Nuoro",
      "Province of Ogliastra",
      "Province of Olbia-Tempio",
      "Province of Oristano",
      "Province of Padua",
      "Province of Parma",
      "Province of Pavia",
      "Province of Perugia",
      "Province of Pescara",
      "Province of Piacenza",
      "Province of Pisa",
      "Province of Pistoia",
      "Province of Pordenone",
      "Province of Potenza",
      "Province of Prato",
      "Province of Ravenna",
      "Province of Reggio Emilia",
      "Province of Rieti",
      "Province of Rimini",
      "Province of Rovigo",
      "Province of Salerno",
      "Province of Sassari",
      "Province of Savona",
      "Province of Siena",
      "Province of Sondrio",
      "Province of Taranto",
      "Province of Teramo",
      "Province of Terni",
      "Province of Treviso",
      "Province of Trieste",
      "Province of Udine",
      "Province of Varese",
      "Province of Verbano-Cusio-Ossola",
      "Province of Vercelli",
      "Province of Verona",
      "Province of Vibo Valentia",
      "Province of Vicenza",
      "Province of Viterbo",
      "Sardinia",
      "Sicily",
      "South Tyrol",
      "Trentino",
      "Trentino-South Tyrol",
      "Tuscany",
      "Umbria",
      "Veneto"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg",
    "dial": "+39",
    "code": "IT"
  },
  {
    "name": "Jamaica",
    "states": [
      "Clarendon Parish",
      "Hanover Parish",
      "Kingston Parish",
      "Manchester Parish",
      "Portland Parish",
      "Saint Andrew",
      "Saint Ann Parish",
      "Saint Catherine Parish",
      "Saint Elizabeth Parish",
      "Saint James Parish",
      "Saint Mary Parish",
      "Saint Thomas Parish",
      "Trelawny Parish",
      "Westmoreland Parish"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg",
    "dial": "+1 876",
    "code": "JM"
  },
  {
    "name": "Japan",
    "states": [
      "Aichi Prefecture",
      "Akita Prefecture",
      "Aomori Prefecture",
      "Chiba Prefecture",
      "Ehime Prefecture",
      "Fukui Prefecture",
      "Fukuoka Prefecture",
      "Fukushima Prefecture",
      "Gifu Prefecture",
      "Gunma Prefecture",
      "Hiroshima Prefecture",
      "Hokkaidō Prefecture",
      "Hyōgo Prefecture",
      "Ibaraki Prefecture",
      "Ishikawa Prefecture",
      "Iwate Prefecture",
      "Kagawa Prefecture",
      "Kagoshima Prefecture",
      "Kanagawa Prefecture",
      "Kumamoto Prefecture",
      "Kyōto Prefecture",
      "Mie Prefecture",
      "Miyagi Prefecture",
      "Miyazaki Prefecture",
      "Nagano Prefecture",
      "Nagasaki Prefecture",
      "Nara Prefecture",
      "Niigata Prefecture",
      "Ōita Prefecture",
      "Okayama Prefecture",
      "Okinawa Prefecture",
      "Ōsaka Prefecture",
      "Saga Prefecture",
      "Saitama Prefecture",
      "Shiga Prefecture",
      "Shimane Prefecture",
      "Shizuoka Prefecture",
      "Tochigi Prefecture",
      "Tokushima Prefecture",
      "Tokyo",
      "Tottori Prefecture",
      "Toyama Prefecture",
      "Wakayama Prefecture",
      "Yamagata Prefecture",
      "Yamaguchi Prefecture",
      "Yamanashi Prefecture"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg",
    "dial": "+81",
    "code": "JP"
  },
  {
    "name": "Jordan",
    "states": [
      "Ajloun Governorate",
      "Amman Governorate",
      "Aqaba Governorate",
      "Balqa Governorate",
      "Irbid Governorate",
      "Jerash Governorate",
      "Karak Governorate",
      "Ma'an Governorate",
      "Madaba Governorate",
      "Mafraq Governorate",
      "Tafilah Governorate",
      "Zarqa Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg",
    "dial": "+962",
    "code": "JO"
  },
  {
    "name": "Kazakhstan",
    "states": [
      "Akmola Region",
      "Aktobe Region",
      "Almaty",
      "Almaty Region",
      "Atyrau Region",
      "Baikonur",
      "East Kazakhstan Region",
      "Jambyl Region",
      "Karaganda Region",
      "Kostanay Region",
      "Kyzylorda Region",
      "Mangystau Region",
      "North Kazakhstan Region",
      "Nur-Sultan",
      "Pavlodar Region",
      "Turkestan Region",
      "West Kazakhstan Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg",
    "dial": "+7",
    "code": "KZ"
  },
  {
    "name": "Kenya",
    "states": [
      "Baringo County",
      "Bomet County",
      "Bungoma County",
      "Busia County",
      "Central Province",
      "Coast Province",
      "Eastern Province",
      "Elgeyo-Marakwet County",
      "Embu County",
      "Garissa County",
      "Homa Bay County",
      "Isiolo County",
      "Kajiado County",
      "Kakamega County",
      "Kericho County",
      "Kiambu County",
      "Kilifi County",
      "Kirinyaga County",
      "Kisii County",
      "Kisumu County",
      "Kitui County",
      "Kwale County",
      "Laikipia County",
      "Lamu County",
      "Machakos County",
      "Makueni County",
      "Mandera County",
      "Marsabit County",
      "Meru County",
      "Migori County",
      "Mombasa County",
      "Muranga County",
      "Nairobi",
      "Nakuru District",
      "Nandi District",
      "Narok County",
      "North Eastern Province",
      "Nyamira District",
      "Nyandarua County",
      "Nyanza Province",
      "Nyeri County",
      "Rift Valley Province",
      "Samburu County",
      "Siaya County",
      "Taita–Taveta County",
      "Tana River County",
      "Tharaka Nithi County",
      "Trans-Nzoia District",
      "Turkana County",
      "Uasin Gishu District",
      "Vihiga District",
      "Wajir County",
      "West Pokot County",
      "Western Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg",
    "dial": "+254",
    "code": "KE"
  },
  {
    "name": "Kiribati",
    "states": [
      "Gilbert Islands",
      "Line Islands",
      "Phoenix Islands"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg",
    "dial": "+686",
    "code": "KI"
  },
  {
    "name": "North Korea",
    "states": [
      "Chagang Province",
      "Kangwon Province",
      "North Hamgyong Province",
      "North Hwanghae Province",
      "North Pyongan Province",
      "Pyongyang",
      "Rason",
      "Ryanggang Province",
      "South Hamgyong Province",
      "South Hwanghae Province",
      "South Pyongan Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg",
    "dial": "+850",
    "code": "KP"
  },
  {
    "name": "South Korea",
    "states": [
      "Busan",
      "Daegu",
      "Daejeon",
      "Gangwon Province",
      "Gwangju",
      "Gyeonggi Province",
      "Incheon",
      "Jeju",
      "North Chungcheong Province",
      "North Gyeongsang Province",
      "North Jeolla Province",
      "Sejong City",
      "Seoul",
      "South Chungcheong Province",
      "South Gyeongsang Province",
      "South Jeolla Province",
      "Ulsan"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg",
    "dial": "+82",
    "code": "KR"
  },
  {
    "name": "Kuwait",
    "states": [
      "Al Ahmadi Governorate",
      "Al Farwaniyah Governorate",
      "Al Jahra Governorate",
      "Capital Governorate",
      "Hawalli Governorate",
      "Mubarak Al-Kabeer Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
    "dial": "+965",
    "code": "KW"
  },
  {
    "name": "Kyrgyzstan",
    "states": [
      "Batken Region",
      "Bishkek",
      "Chuy Region",
      "Issyk-Kul Region",
      "Jalal-Abad Region",
      "Naryn Region",
      "Osh",
      "Osh Region",
      "Talas Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg",
    "dial": "+996",
    "code": "KG"
  },
  {
    "name": "Laos",
    "states": [
      "Attapeu Province",
      "Bokeo Province",
      "Bolikhamsai Province",
      "Champasak Province",
      "Houaphanh Province",
      "Khammouane Province",
      "Luang Namtha Province",
      "Luang Prabang Province",
      "Oudomxay Province",
      "Phongsaly Province",
      "Sainyabuli Province",
      "Salavan Province",
      "Savannakhet Province",
      "Sekong Province",
      "Vientiane Prefecture",
      "Vientiane Province",
      "Xaisomboun",
      "Xaisomboun Province",
      "Xiangkhouang Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg",
    "dial": "+856",
    "code": "LA"
  },
  {
    "name": "Latvia",
    "states": [
      "Aglona Municipality",
      "Aizkraukle Municipality",
      "Aizpute Municipality",
      "Aknīste Municipality",
      "Aloja Municipality",
      "Alsunga Municipality",
      "Alūksne Municipality",
      "Amata Municipality",
      "Ape Municipality",
      "Auce Municipality",
      "Babīte Municipality",
      "Baldone Municipality",
      "Baltinava Municipality",
      "Balvi Municipality",
      "Bauska Municipality",
      "Beverīna Municipality",
      "Brocēni Municipality",
      "Burtnieki Municipality",
      "Carnikava Municipality",
      "Cēsis Municipality",
      "Cesvaine Municipality",
      "Cibla Municipality",
      "Dagda Municipality",
      "Daugavpils",
      "Daugavpils Municipality",
      "Dobele Municipality",
      "Dundaga Municipality",
      "Durbe Municipality",
      "Engure Municipality",
      "Ērgļi Municipality",
      "Garkalne Municipality",
      "Grobiņa Municipality",
      "Gulbene Municipality",
      "Iecava Municipality",
      "Ikšķile Municipality",
      "Ilūkste Municipality",
      "Inčukalns Municipality",
      "Jaunjelgava Municipality",
      "Jaunpiebalga Municipality",
      "Jaunpils Municipality",
      "Jēkabpils",
      "Jēkabpils Municipality",
      "Jelgava",
      "Jelgava Municipality",
      "Jūrmala",
      "Kandava Municipality",
      "Kārsava Municipality",
      "Ķegums Municipality",
      "Ķekava Municipality",
      "Kocēni Municipality",
      "Koknese Municipality",
      "Krāslava Municipality",
      "Krimulda Municipality",
      "Krustpils Municipality",
      "Kuldīga Municipality",
      "Lielvārde Municipality",
      "Liepāja",
      "Līgatne Municipality",
      "Limbaži Municipality",
      "Līvāni Municipality",
      "Lubāna Municipality",
      "Ludza Municipality",
      "Madona Municipality",
      "Mālpils Municipality",
      "Mārupe Municipality",
      "Mazsalaca Municipality",
      "Mērsrags Municipality",
      "Naukšēni Municipality",
      "Nereta Municipality",
      "Nīca Municipality",
      "Ogre Municipality",
      "Olaine Municipality",
      "Ozolnieki Municipality",
      "Pārgauja Municipality",
      "Pāvilosta Municipality",
      "Pļaviņas Municipality",
      "Preiļi Municipality",
      "Priekule Municipality",
      "Priekuļi Municipality",
      "Rauna Municipality",
      "Rēzekne",
      "Rēzekne Municipality",
      "Riebiņi Municipality",
      "Riga",
      "Roja Municipality",
      "Ropaži Municipality",
      "Rucava Municipality",
      "Rugāji Municipality",
      "Rūjiena Municipality",
      "Rundāle Municipality",
      "Sala Municipality",
      "Salacgrīva Municipality",
      "Salaspils Municipality",
      "Saldus Municipality",
      "Saulkrasti Municipality",
      "Sēja Municipality",
      "Sigulda Municipality",
      "Skrīveri Municipality",
      "Skrunda Municipality",
      "Smiltene Municipality",
      "Stopiņi Municipality",
      "Strenči Municipality",
      "Talsi Municipality",
      "Tērvete Municipality",
      "Tukums Municipality",
      "Vaiņode Municipality",
      "Valka Municipality",
      "Valmiera",
      "Varakļāni Municipality",
      "Vārkava Municipality",
      "Vecpiebalga Municipality",
      "Vecumnieki Municipality",
      "Ventspils",
      "Ventspils Municipality",
      "Viesīte Municipality",
      "Viļaka Municipality",
      "Viļāni Municipality",
      "Zilupe Municipality"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg",
    "dial": "+371",
    "code": "LV"
  },
  {
    "name": "Lebanon",
    "states": [
      "Akkar Governorate",
      "Baalbek-Hermel Governorate",
      "Beirut Governorate",
      "Beqaa Governorate",
      "Mount Lebanon Governorate",
      "Nabatieh Governorate",
      "North Governorate",
      "South Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg",
    "dial": "+961",
    "code": "LB"
  },
  {
    "name": "Lesotho",
    "states": [
      "Berea District",
      "Butha-Buthe District",
      "Leribe District",
      "Mafeteng District",
      "Maseru District",
      "Mohale's Hoek District",
      "Mokhotlong District",
      "Qacha's Nek District",
      "Quthing District",
      "Thaba-Tseka District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg",
    "dial": "+266",
    "code": "LS"
  },
  {
    "name": "Liberia",
    "states": [
      "Bomi County",
      "Bong County",
      "Gbarpolu County",
      "Grand Bassa County",
      "Grand Cape Mount County",
      "Grand Gedeh County",
      "Grand Kru County",
      "Lofa County",
      "Margibi County",
      "Maryland County",
      "Montserrado County",
      "Nimba",
      "River Cess County",
      "River Gee County",
      "Sinoe County"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg",
    "dial": "+231",
    "code": "LR"
  },
  {
    "name": "Libya",
    "states": [
      "Al Wahat District",
      "Benghazi",
      "Derna District",
      "Ghat District",
      "Jabal al Akhdar",
      "Jabal al Gharbi District",
      "Jafara",
      "Jufra",
      "Kufra District",
      "Marj District",
      "Misrata District",
      "Murqub",
      "Murzuq District",
      "Nalut District",
      "Nuqat al Khams",
      "Sabha District",
      "Sirte District",
      "Tripoli District",
      "Wadi al Hayaa District",
      "Wadi al Shatii District",
      "Zawiya District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg",
    "dial": "+218",
    "code": "LY"
  },
  {
    "name": "Liechtenstein",
    "states": [
      "Balzers",
      "Eschen",
      "Gamprin",
      "Mauren",
      "Planken",
      "Ruggell",
      "Schaan",
      "Schellenberg",
      "Triesen",
      "Triesenberg",
      "Vaduz"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg",
    "dial": "+423",
    "code": "LI"
  },
  {
    "name": "Lithuania",
    "states": [
      "Akmenė District Municipality",
      "Alytus City Municipality",
      "Alytus County",
      "Alytus District Municipality",
      "Birštonas Municipality",
      "Biržai District Municipality",
      "Druskininkai municipality",
      "Elektrėnai municipality",
      "Ignalina District Municipality",
      "Jonava District Municipality",
      "Joniškis District Municipality",
      "Jurbarkas District Municipality",
      "Kaišiadorys District Municipality",
      "Kalvarija municipality",
      "Kaunas City Municipality",
      "Kaunas County",
      "Kaunas District Municipality",
      "Kazlų Rūda municipality",
      "Kėdainiai District Municipality",
      "Kelmė District Municipality",
      "Klaipeda City Municipality",
      "Klaipėda County",
      "Klaipėda District Municipality",
      "Kretinga District Municipality",
      "Kupiškis District Municipality",
      "Lazdijai District Municipality",
      "Marijampolė County",
      "Marijampolė Municipality",
      "Mažeikiai District Municipality",
      "Molėtai District Municipality",
      "Neringa Municipality",
      "Pagėgiai municipality",
      "Pakruojis District Municipality",
      "Palanga City Municipality",
      "Panevėžys City Municipality",
      "Panevėžys County",
      "Panevėžys District Municipality",
      "Pasvalys District Municipality",
      "Plungė District Municipality",
      "Prienai District Municipality",
      "Radviliškis District Municipality",
      "Raseiniai District Municipality",
      "Rietavas municipality",
      "Rokiškis District Municipality",
      "Šakiai District Municipality",
      "Šalčininkai District Municipality",
      "Šiauliai City Municipality",
      "Šiauliai County",
      "Šiauliai District Municipality",
      "Šilalė District Municipality",
      "Šilutė District Municipality",
      "Širvintos District Municipality",
      "Skuodas District Municipality",
      "Švenčionys District Municipality",
      "Tauragė County",
      "Tauragė District Municipality",
      "Telšiai County",
      "Telšiai District Municipality",
      "Trakai District Municipality",
      "Ukmergė District Municipality",
      "Utena County",
      "Utena District Municipality",
      "Varėna District Municipality",
      "Vilkaviškis District Municipality",
      "Vilnius City Municipality",
      "Vilnius County",
      "Vilnius District Municipality",
      "Visaginas Municipality",
      "Zarasai District Municipality"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg",
    "dial": "+370",
    "code": "LT"
  },
  {
    "name": "Luxembourg",
    "states": [
      "Canton of Capellen",
      "Canton of Clervaux",
      "Canton of Diekirch",
      "Canton of Echternach",
      "Canton of Esch-sur-Alzette",
      "Canton of Grevenmacher",
      "Canton of Luxembourg",
      "Canton of Mersch",
      "Canton of Redange",
      "Canton of Remich",
      "Canton of Vianden",
      "Canton of Wiltz",
      "Diekirch District",
      "Grevenmacher District",
      "Luxembourg District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg",
    "dial": "+352",
    "code": "LU"
  },
  {
    "name": "Macedonia",
    "states": [
      "Aerodrom Municipality",
      "Aračinovo Municipality",
      "Berovo Municipality",
      "Bitola Municipality",
      "Bogdanci Municipality",
      "Bogovinje Municipality",
      "Bosilovo Municipality",
      "Brvenica Municipality",
      "Butel Municipality",
      "Čair Municipality",
      "Čaška Municipality",
      "Centar Municipality",
      "Centar Župa Municipality",
      "Češinovo-Obleševo Municipality",
      "Čučer-Sandevo Municipality",
      "Debarca Municipality",
      "Delčevo Municipality",
      "Demir Hisar Municipality",
      "Demir Kapija Municipality",
      "Dojran Municipality",
      "Dolneni Municipality",
      "Drugovo Municipality",
      "Gazi Baba Municipality",
      "Gevgelija Municipality",
      "Gjorče Petrov Municipality",
      "Gostivar Municipality",
      "Gradsko Municipality",
      "Greater Skopje",
      "Ilinden Municipality",
      "Jegunovce Municipality",
      "Karbinci",
      "Karpoš Municipality",
      "Kavadarci Municipality",
      "Kičevo Municipality",
      "Kisela Voda Municipality",
      "Kočani Municipality",
      "Konče Municipality",
      "Kratovo Municipality",
      "Kriva Palanka Municipality",
      "Krivogaštani Municipality",
      "Kruševo Municipality",
      "Kumanovo Municipality",
      "Lipkovo Municipality",
      "Lozovo Municipality",
      "Makedonska Kamenica Municipality",
      "Makedonski Brod Municipality",
      "Mavrovo and Rostuša Municipality",
      "Mogila Municipality",
      "Negotino Municipality",
      "Novaci Municipality",
      "Novo Selo Municipality",
      "Ohrid Municipality",
      "Oslomej Municipality",
      "Pehčevo Municipality",
      "Petrovec Municipality",
      "Plasnica Municipality",
      "Prilep Municipality",
      "Probištip Municipality",
      "Radoviš Municipality",
      "Rankovce Municipality",
      "Resen Municipality",
      "Rosoman Municipality",
      "Saraj Municipality",
      "Sopište Municipality",
      "Staro Nagoričane Municipality",
      "Štip Municipality",
      "Struga Municipality",
      "Strumica Municipality",
      "Studeničani Municipality",
      "Šuto Orizari Municipality",
      "Sveti Nikole Municipality",
      "Tearce Municipality",
      "Tetovo Municipality",
      "Valandovo Municipality",
      "Vasilevo Municipality",
      "Veles Municipality",
      "Vevčani Municipality",
      "Vinica Municipality",
      "Vraneštica Municipality",
      "Vrapčište Municipality",
      "Zajas Municipality",
      "Zelenikovo Municipality",
      "Želino Municipality",
      "Zrnovci Municipality"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg",
    "dial": "+389",
    "code": "MK"
  },
  {
    "name": "Madagascar",
    "states": [
      "Antananarivo Province",
      "Antsiranana Province",
      "Fianarantsoa Province",
      "Mahajanga Province",
      "Toamasina Province",
      "Toliara Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg",
    "dial": "+261",
    "code": "MG"
  },
  {
    "name": "Malawi",
    "states": [
      "Balaka District",
      "Blantyre District",
      "Central Region",
      "Chikwawa District",
      "Chiradzulu District",
      "Chitipa district",
      "Dedza District",
      "Dowa District",
      "Karonga District",
      "Kasungu District",
      "Likoma District",
      "Lilongwe District",
      "Machinga District",
      "Mangochi District",
      "Mchinji District",
      "Mulanje District",
      "Mwanza District",
      "Mzimba District",
      "Nkhata Bay District",
      "Nkhotakota District",
      "Northern Region",
      "Nsanje District",
      "Ntcheu District",
      "Ntchisi District",
      "Phalombe District",
      "Rumphi District",
      "Salima District",
      "Southern Region",
      "Thyolo District",
      "Zomba District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg",
    "dial": "+265",
    "code": "MW"
  },
  {
    "name": "Malaysia",
    "states": [
      "Johor",
      "Kedah",
      "Kelantan",
      "Kuala Lumpur",
      "Labuan",
      "Melaka",
      "Negeri Sembilan",
      "Pahang",
      "Penang",
      "Perak",
      "Perlis",
      "Putrajaya",
      "Sabah",
      "Sarawak",
      "Selangor",
      "Terengganu"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg",
    "dial": "+60",
    "code": "MY"
  },
  {
    "name": "Maldives",
    "states": [
      "Addu Atoll",
      "Alif Alif Atoll",
      "Alif Dhaal Atoll",
      "Central Province",
      "Dhaalu Atoll",
      "Faafu Atoll",
      "Gaafu Alif Atoll",
      "Gaafu Dhaalu Atoll",
      "Gnaviyani Atoll",
      "Haa Alif Atoll",
      "Haa Dhaalu Atoll",
      "Kaafu Atoll",
      "Laamu Atoll",
      "Lhaviyani Atoll",
      "Malé",
      "Meemu Atoll",
      "Noonu Atoll",
      "North Central Province",
      "North Province",
      "Raa Atoll",
      "Shaviyani Atoll",
      "South Central Province",
      "South Province",
      "Thaa Atoll",
      "Upper South Province",
      "Vaavu Atoll"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg",
    "dial": "+960",
    "code": "MV"
  },
  {
    "name": "Mali",
    "states": [
      "Bamako",
      "Gao Region",
      "Kayes Region",
      "Kidal Region",
      "Koulikoro Region",
      "Ménaka Region",
      "Mopti Region",
      "Ségou Region",
      "Sikasso Region",
      "Taoudénit Region",
      "Tombouctou Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg",
    "dial": "+223",
    "code": "ML"
  },
  {
    "name": "Malta",
    "states": [
      "Attard",
      "Balzan",
      "Birgu",
      "Birkirkara",
      "Birżebbuġa",
      "Cospicua",
      "Dingli",
      "Fgura",
      "Floriana",
      "Fontana",
      "Għajnsielem",
      "Għarb",
      "Għargħur",
      "Għasri",
      "Għaxaq",
      "Gudja",
      "Gżira",
      "Ħamrun",
      "Iklin",
      "Kalkara",
      "Kerċem",
      "Kirkop",
      "Lija",
      "Luqa",
      "Marsa",
      "Marsaskala",
      "Marsaxlokk",
      "Mdina",
      "Mellieħa",
      "Mġarr",
      "Mosta",
      "Mqabba",
      "Msida",
      "Mtarfa",
      "Munxar",
      "Nadur",
      "Naxxar",
      "Paola",
      "Pembroke",
      "Pietà",
      "Qala",
      "Qormi",
      "Qrendi",
      "Rabat",
      "Saint Lawrence",
      "San Ġwann",
      "Sannat",
      "Santa Luċija",
      "Santa Venera",
      "Senglea",
      "Siġġiewi",
      "Sliema",
      "St. Julian's",
      "St. Paul's Bay",
      "Swieqi",
      "Ta' Xbiex",
      "Tarxien",
      "Valletta",
      "Victoria",
      "Xagħra",
      "Xewkija",
      "Xgħajra",
      "Żabbar",
      "Żebbuġ Gozo",
      "Żebbuġ Malta",
      "Żejtun",
      "Żurrieq"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg",
    "dial": "+356",
    "code": "MT"
  },
  {
    "name": "Marshall Islands",
    "states": [
      "Ralik Chain",
      "Ratak Chain"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg",
    "dial": "+692",
    "code": "MH"
  },
  {
    "name": "Mauritania",
    "states": [
      "Adrar Region",
      "Assaba Region",
      "Brakna Region",
      "Dakhlet Nouadhibou",
      "Gorgol Region",
      "Guidimaka Region",
      "Hodh Ech Chargui Region",
      "Hodh El Gharbi Region",
      "Inchiri Region",
      "Nouakchott-Nord Region",
      "Nouakchott-Ouest Region",
      "Nouakchott-Sud Region",
      "Tagant Region",
      "Tiris Zemmour Region",
      "Trarza Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg",
    "dial": "+222",
    "code": "MR"
  },
  {
    "name": "Mauritius",
    "states": [
      "Agaléga",
      "Beau Bassin-Rose Hill",
      "Cargados Carajos",
      "Curepipe",
      "Flacq District",
      "Grand Port District",
      "Moka District",
      "Pamplemousses District",
      "Plaines Wilhems District",
      "Port Louis",
      "Port Louis District",
      "Quatre Bornes",
      "Rivière du Rempart District",
      "Rivière Noire District",
      "Rodrigues",
      "Savanne District",
      "Vacoas-Phoenix"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg",
    "dial": "+230",
    "code": "MU"
  },
  {
    "name": "Mexico",
    "states": [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Coahuila",
      "Colima",
      "Durango",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "México",
      "Mexico City",
      "Michoacán",
      "Morelos",
      "Nayarit",
      "Nuevo León",
      "Oaxaca",
      "Puebla",
      "Querétaro",
      "Quintana Roo",
      "San Luis Potosí",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz",
      "Yucatán",
      "Zacatecas"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg",
    "dial": "+52",
    "code": "MX"
  },
  {
    "name": "Micronesia",
    "states": [
      "Chuuk State",
      "Kosrae State",
      "Pohnpei State",
      "Yap State"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg",
    "dial": "+691",
    "code": "FM"
  },
  {
    "name": "Moldova",
    "states": [
      "Anenii Noi District",
      "Bălți Municipality",
      "Basarabeasca District",
      "Bender Municipality",
      "Briceni District",
      "Cahul District",
      "Călărași District",
      "Cantemir District",
      "Căușeni District",
      "Chișinău Municipality",
      "Cimișlia District",
      "Criuleni District",
      "Dondușeni District",
      "Drochia District",
      "Dubăsari District",
      "Edineț District",
      "Fălești District",
      "Florești District",
      "Gagauzia",
      "Glodeni District",
      "Hîncești District",
      "Ialoveni District",
      "Nisporeni District",
      "Ocnița District",
      "Orhei District",
      "Rezina District",
      "Rîșcani District",
      "Sîngerei District",
      "Șoldănești District",
      "Soroca District",
      "Ștefan Vodă District",
      "Strășeni District",
      "Taraclia District",
      "Telenești District",
      "Transnistria autonomous territorial unit",
      "Ungheni District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg",
    "dial": "+373",
    "code": "MD"
  },
  {
    "name": "Mongolia",
    "states": [
      "Arkhangai Province",
      "Bayan-Ölgii Province",
      "Bayankhongor Province",
      "Bulgan Province",
      "Darkhan-Uul Province",
      "Dornod Province",
      "Dornogovi Province",
      "Dundgovi Province",
      "Govi-Altai Province",
      "Govisümber Province",
      "Khentii Province",
      "Khovd Province",
      "Khövsgöl Province",
      "Ömnögovi Province",
      "Orkhon Province",
      "Övörkhangai Province",
      "Selenge Province",
      "Sükhbaatar Province",
      "Töv Province",
      "Uvs Province",
      "Zavkhan Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg",
    "dial": "+976",
    "code": "MN"
  },
  {
    "name": "Montenegro",
    "states": [
      "Andrijevica Municipality",
      "Bar Municipality",
      "Berane Municipality",
      "Bijelo Polje Municipality",
      "Budva Municipality",
      "Danilovgrad Municipality",
      "Gusinje Municipality",
      "Kolašin Municipality",
      "Kotor Municipality",
      "Mojkovac Municipality",
      "Nikšić Municipality",
      "Old Royal Capital Cetinje",
      "Petnjica Municipality",
      "Plav Municipality",
      "Pljevlja Municipality",
      "Plužine Municipality",
      "Podgorica Municipality",
      "Rožaje Municipality",
      "Šavnik Municipality",
      "Tivat Municipality",
      "Ulcinj Municipality",
      "Žabljak Municipality"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg",
    "dial": "+382",
    "code": "ME"
  },
  {
    "name": "Morocco",
    "states": [
      "Al Haouz Province",
      "Al Hoceïma Province",
      "Aousserd Province",
      "Assa-Zag Province",
      "Azilal Province",
      "Ben Slimane Province",
      "Béni Mellal-Khénifra",
      "Béni-Mellal Province",
      "Berkane Province",
      "Boujdour Province",
      "Boulemane Province",
      "Casablanca-Settat",
      "Chefchaouen Province",
      "Chichaoua Province",
      "Dakhla-Oued Ed-Dahab",
      "Drâa-Tafilalet",
      "El Hajeb Province",
      "El Jadida Province",
      "Errachidia Province",
      "Es Semara Province",
      "Essaouira Province",
      "Fahs Anjra Province",
      "Fès-Meknès",
      "Figuig Province",
      "Guelmim Province",
      "Guelmim-Oued Noun",
      "Ifrane Province",
      "Inezgane-Aït Melloul Prefecture",
      "Jerada Province",
      "Kelaat Sraghna Province",
      "Kénitra Province",
      "Khémisset Province",
      "Khénifra Province",
      "Khouribga Province",
      "Laâyoune Province",
      "Laâyoune-Sakia El Hamra",
      "Larache Province",
      "Marrakesh-Safi",
      "Mediouna Province",
      "Moulay Yacoub Province",
      "Nador Province",
      "Nouaceur Province",
      "Oriental",
      "Ouarzazate Province",
      "Oued Ed-Dahab Province",
      "Safi Province",
      "Sefrou Province",
      "Settat Province",
      "Shtouka Ait Baha Province",
      "Sidi Kacem Province",
      "Sidi Youssef Ben Ali",
      "Souss-Massa",
      "Tan-Tan Province",
      "Tanger-Tétouan-Al Hoceïma",
      "Taounate Province",
      "Taourirt Province",
      "Taroudant Province",
      "Tata Province",
      "Taza Province",
      "Tétouan Province",
      "Tiznit Province",
      "Zagora Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg",
    "dial": "+212",
    "code": "MA"
  },
  {
    "name": "Mozambique",
    "states": [
      "Cabo Delgado Province",
      "Gaza Province",
      "Inhambane Province",
      "Manica Province",
      "Maputo",
      "Maputo Province",
      "Nampula Province",
      "Niassa Province",
      "Sofala Province",
      "Tete Province",
      "Zambezia Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg",
    "dial": "+258",
    "code": "MZ"
  },
  {
    "name": "Myanmar",
    "states": [
      "Ayeyarwady Region",
      "Bago",
      "Chin State",
      "Kachin State",
      "Kayah State",
      "Kayin State",
      "Magway Region",
      "Mandalay Region",
      "Mon State",
      "Naypyidaw Union Territory",
      "Rakhine State",
      "Sagaing Region",
      "Shan State",
      "Tanintharyi Region",
      "Yangon Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg",
    "dial": "+95",
    "code": "MM"
  },
  {
    "name": "Namibia",
    "states": [
      "Erongo Region",
      "Hardap Region",
      "Karas Region",
      "Kavango East Region",
      "Kavango West Region",
      "Khomas Region",
      "Kunene Region",
      "Ohangwena Region",
      "Omaheke Region",
      "Omusati Region",
      "Oshana Region",
      "Oshikoto Region",
      "Otjozondjupa Region",
      "Zambezi Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg",
    "dial": "+264",
    "code": "NA"
  },
  {
    "name": "Nauru",
    "states": [
      "Aiwo District",
      "Anabar District",
      "Anetan District",
      "Anibare District",
      "Baiti District",
      "Boe District",
      "Buada District",
      "Denigomodu District",
      "Ewa District",
      "Ijuw District",
      "Meneng District",
      "Nibok District",
      "Uaboe District",
      "Yaren District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg",
    "dial": "+674",
    "code": "NR"
  },
  {
    "name": "Nepal",
    "states": [
      "Bagmati Zone",
      "Bheri Zone",
      "Central Region",
      "Dhaulagiri Zone",
      "Eastern Development Region",
      "Far-Western Development Region",
      "Gandaki Zone",
      "Janakpur Zone",
      "Karnali Zone",
      "Kosi Zone",
      "Lumbini Zone",
      "Mahakali Zone",
      "Mechi Zone",
      "Mid-Western Region",
      "Narayani Zone",
      "Rapti Zone",
      "Sagarmatha Zone",
      "Seti Zone",
      "Western Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg",
    "dial": "+977",
    "code": "NP"
  },
  {
    "name": "Netherlands",
    "states": [
      "Drenthe",
      "Flevoland",
      "Friesland",
      "Gelderland",
      "Groningen",
      "Limburg",
      "North Brabant",
      "North Holland",
      "Overijssel",
      "South Holland",
      "Utrecht",
      "Zeeland"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg",
    "dial": "+31",
    "code": "NL"
  },
  {
    "name": "New Zealand",
    "states": [
      "Auckland Region",
      "Bay of Plenty Region",
      "Canterbury Region",
      "Chatham Islands",
      "Gisborne District",
      "Hawke's Bay Region",
      "Manawatu-Wanganui Region",
      "Marlborough Region",
      "Nelson Region",
      "Northland Region",
      "Otago Region",
      "Southland Region",
      "Taranaki Region",
      "Tasman District",
      "Waikato Region",
      "Wellington Region",
      "West Coast Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg",
    "dial": "+64",
    "code": "NZ"
  },
  {
    "name": "Nicaragua",
    "states": [
      "Boaco Department",
      "Carazo Department",
      "Chinandega Department",
      "Chontales Department",
      "Estelí Department",
      "Granada Department",
      "Jinotega Department",
      "León Department",
      "Madriz Department",
      "Managua Department",
      "Masaya Department",
      "Matagalpa Department",
      "North Caribbean Coast Autonomous Region",
      "Río San Juan Department",
      "Rivas Department",
      "South Caribbean Coast Autonomous Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg",
    "dial": "+505",
    "code": "NI"
  },
  {
    "name": "Niger",
    "states": [
      "Agadez Region",
      "Diffa Region",
      "Dosso Region",
      "Maradi Region",
      "Tahoua Region",
      "Tillabéri Region",
      "Zinder Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg",
    "dial": "+227",
    "code": "NE"
  },
  {
    "name": "Nigeria",
    "states": [
      "Abia State",
      "Adamawa State",
      "Akwa Ibom State",
      "Anambra State",
      "Bauchi State",
      "Bayelsa State",
      "Benue State",
      "Borno State",
      "Cross River State",
      "Delta State",
      "Ebonyi State",
      "Edo State",
      "Ekiti State",
      "Enugu State",
      "Federal Capital Territory",
      "Gombe State",
      "Imo State",
      "Jigawa State",
      "Kaduna State",
      "Kano State",
      "Katsina State",
      "Kebbi State",
      "Kogi State",
      "Kwara State",
      "Lagos",
      "Nasarawa State",
      "Niger State",
      "Ogun State",
      "Ondo State",
      "Osun State",
      "Oyo State",
      "Plateau State",
      "Sokoto State",
      "Taraba State",
      "Yobe State",
      "Zamfara State"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg",
    "dial": "+234",
    "code": "NG"
  },
  {
    "name": "Norway",
    "states": [
      "Akershus",
      "Buskerud",
      "Finnmark",
      "Hedmark",
      "Hordaland",
      "Jan Mayen",
      "Møre og Romsdal",
      "Nord-Trøndelag",
      "Nordland",
      "Oppland",
      "Oslo",
      "Østfold",
      "Rogaland",
      "Sogn og Fjordane",
      "Sør-Trøndelag",
      "Svalbard",
      "Telemark",
      "Troms",
      "Trøndelag",
      "Vest-Agder",
      "Vestfold"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg",
    "dial": "+47",
    "code": "NO"
  },
  {
    "name": "Oman",
    "states": [
      "Ad Dakhiliyah Governorate",
      "Ad Dhahirah Governorate",
      "Al Batinah North Governorate",
      "Al Batinah Region",
      "Al Batinah South Governorate",
      "Al Buraimi Governorate",
      "Al Wusta Governorate",
      "Ash Sharqiyah North Governorate",
      "Ash Sharqiyah Region",
      "Ash Sharqiyah South Governorate",
      "Dhofar Governorate",
      "Musandam Governorate",
      "Muscat Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg",
    "dial": "+968",
    "code": "OM"
  },
  {
    "name": "Pakistan",
    "states": [
      "Azad Kashmir",
      "Balochistan",
      "Federally Administered Tribal Areas",
      "Gilgit-Baltistan",
      "Islamabad Capital Territory",
      "Khyber Pakhtunkhwa",
      "Punjab",
      "Sindh"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg",
    "dial": "+92",
    "code": "PK"
  },
  {
    "name": "Palau",
    "states": [
      "Aimeliik",
      "Airai",
      "Angaur",
      "Hatohobei",
      "Kayangel",
      "Koror",
      "Melekeok",
      "Ngaraard",
      "Ngarchelong",
      "Ngardmau",
      "Ngatpang",
      "Ngchesar",
      "Ngeremlengui",
      "Ngiwal",
      "Peleliu",
      "Sonsorol"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg",
    "dial": "+680",
    "code": "PW"
  },
  {
    "name": "Panama",
    "states": [
      "Bocas del Toro Province",
      "Chiriquí Province",
      "Coclé Province",
      "Colón Province",
      "Darién Province",
      "Emberá-Wounaan Comarca",
      "Guna Yala",
      "Herrera Province",
      "Los Santos Province",
      "Ngöbe-Buglé Comarca",
      "Panamá Oeste Province",
      "Panamá Province",
      "Veraguas Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg",
    "dial": "+507",
    "code": "PA"
  },
  {
    "name": "Papua new Guinea",
    "states": [
      "Bougainville",
      "Central Province",
      "Chimbu Province",
      "East New Britain",
      "Eastern Highlands Province",
      "Enga Province",
      "Gulf",
      "Hela",
      "Jiwaka Province",
      "Madang Province",
      "Manus Province",
      "Milne Bay Province",
      "Morobe Province",
      "New Ireland Province",
      "Oro Province",
      "Port Moresby",
      "Sandaun Province",
      "Southern Highlands Province",
      "West New Britain Province",
      "Western Highlands Province",
      "Western Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg",
    "dial": "+675",
    "code": "PG"
  },
  {
    "name": "Paraguay",
    "states": [
      "Alto Paraguay Department",
      "Alto Paraná Department",
      "Amambay Department",
      "Boquerón Department",
      "Caaguazú",
      "Caazapá",
      "Canindeyú",
      "Central Department",
      "Concepción Department",
      "Cordillera Department",
      "Guairá Department",
      "Itapúa",
      "Misiones Department",
      "Ñeembucú Department",
      "Paraguarí Department",
      "Presidente Hayes Department",
      "San Pedro Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg",
    "dial": "+595",
    "code": "PY"
  },
  {
    "name": "Peru",
    "states": [
      "Amazonas",
      "Áncash",
      "Apurímac",
      "Arequipa",
      "Ayacucho",
      "Cajamarca",
      "Callao",
      "Cusco",
      "Huancavelica",
      "Huanuco",
      "Ica",
      "Junín",
      "La Libertad",
      "Lambayeque",
      "Lima",
      "Madre de Dios",
      "Moquegua",
      "Pasco",
      "Piura",
      "Puno",
      "San Martín",
      "Tacna",
      "Tumbes",
      "Ucayali"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg",
    "dial": "+51",
    "code": "PE"
  },
  {
    "name": "Philippines",
    "states": [
      "Abra",
      "Agusan del Norte",
      "Agusan del Sur",
      "Aklan",
      "Albay",
      "Antique",
      "Apayao",
      "Aurora",
      "Autonomous Region in Muslim Mindanao",
      "Basilan",
      "Bataan",
      "Batanes",
      "Batangas",
      "Benguet",
      "Bicol Region",
      "Biliran",
      "Bohol",
      "Bukidnon",
      "Bulacan",
      "Cagayan",
      "Cagayan Valley",
      "Calabarzon",
      "Camarines Norte",
      "Camarines Sur",
      "Camiguin",
      "Capiz",
      "Caraga",
      "Catanduanes",
      "Cavite",
      "Cebu",
      "Central Luzon",
      "Central Visayas",
      "Compostela Valley",
      "Cordillera Administrative Region",
      "Cotabato",
      "Davao del Norte",
      "Davao del Sur",
      "Davao Occidental",
      "Davao Oriental",
      "Davao Region",
      "Dinagat Islands",
      "Eastern Samar",
      "Eastern Visayas",
      "Guimaras",
      "Ifugao",
      "Ilocos Norte",
      "Ilocos Region",
      "Ilocos Sur",
      "Iloilo",
      "Isabela",
      "Kalinga",
      "La Union",
      "Laguna",
      "Lanao del Norte",
      "Lanao del Sur",
      "Leyte",
      "Maguindanao",
      "Marinduque",
      "Masbate",
      "Metro Manila",
      "Mimaropa",
      "Misamis Occidental",
      "Misamis Oriental",
      "Mountain Province",
      "Negros Occidental",
      "Negros Oriental",
      "Northern Mindanao",
      "Northern Samar",
      "Nueva Ecija",
      "Nueva Vizcaya",
      "Occidental Mindoro",
      "Oriental Mindoro",
      "Palawan",
      "Pampanga",
      "Pangasinan",
      "Quezon",
      "Quirino",
      "Rizal",
      "Romblon",
      "Sarangani",
      "Siquijor",
      "Soccsksargen",
      "Sorsogon",
      "South Cotabato",
      "Southern Leyte",
      "Sultan Kudarat",
      "Sulu",
      "Surigao del Norte",
      "Surigao del Sur",
      "Tarlac",
      "Tawi-Tawi",
      "Western Visayas",
      "Zambales",
      "Zamboanga del Norte",
      "Zamboanga del Sur",
      "Zamboanga Peninsula",
      "Zamboanga Sibugay"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg",
    "dial": "+63",
    "code": "PH"
  },
  {
    "name": "Poland",
    "states": [
      "Greater Poland Voivodeship",
      "Kielce",
      "Kuyavian-Pomeranian Voivodeship",
      "Lesser Poland Voivodeship",
      "Lower Silesian Voivodeship",
      "Lublin Voivodeship",
      "Lubusz Voivodeship",
      "Łódź Voivodeship",
      "Masovian Voivodeship",
      "Opole Voivodeship",
      "Podkarpackie Voivodeship",
      "Podlaskie Voivodeship",
      "Pomeranian Voivodeship",
      "Silesian Voivodeship",
      "Świętokrzyskie Voivodeship",
      "Warmian-Masurian Voivodeship",
      "West Pomeranian Voivodeship"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg",
    "dial": "+48",
    "code": "PL"
  },
  {
    "name": "Portugal",
    "states": [
      "Aveiro District",
      "Azores",
      "Beja District",
      "Braga District",
      "Bragança District",
      "Castelo Branco District",
      "Coimbra District",
      "Évora District",
      "Faro District",
      "Guarda District",
      "Leiria District",
      "Lisbon District",
      "Madeira",
      "Portalegre District",
      "Porto District",
      "Santarém District",
      "Setúbal District",
      "Viana do Castelo District",
      "Vila Real District",
      "Viseu District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg",
    "dial": "+351",
    "code": "PT"
  },
  {
    "name": "Qatar",
    "states": [
      "Al Daayen",
      "Al Khor",
      "Al Rayyan Municipality",
      "Al Wakrah",
      "Al-Shahaniya",
      "Doha",
      "Madinat ash Shamal",
      "Umm Salal Municipality"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg",
    "dial": "+974",
    "code": "QA"
  },
  {
    "name": "Romania",
    "states": [
      "Alba",
      "Arad County",
      "Arges",
      "Bacău County",
      "Bihor County",
      "Bistrița-Năsăud County",
      "Botoșani County",
      "Braila",
      "Brașov County",
      "Bucharest",
      "Buzău County",
      "Călărași County",
      "Caraș-Severin County",
      "Cluj County",
      "Constanța County",
      "Covasna County",
      "Dâmbovița County",
      "Dolj County",
      "Galați County",
      "Giurgiu County",
      "Gorj County",
      "Harghita County",
      "Hunedoara County",
      "Ialomița County",
      "Iași County",
      "Ilfov County",
      "Maramureș County",
      "Mehedinți County",
      "Neamț County",
      "Olt County",
      "Prahova County",
      "Sălaj County",
      "Satu Mare County",
      "Sibiu County",
      "Suceava County",
      "Teleorman County",
      "Timiș County",
      "Tulcea County",
      "Vâlcea County",
      "Vaslui County",
      "Vrancea County"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg",
    "dial": "+40",
    "code": "RO"
  },
  {
    "name": "Russia",
    "states": [
      "Altai Krai",
      "Altai Republic",
      "Amur Oblast",
      "Arkhangelsk",
      "Astrakhan Oblast",
      "Belgorod Oblast",
      "Bryansk Oblast",
      "Chechen Republic",
      "Chelyabinsk Oblast",
      "Chukotka Autonomous Okrug",
      "Chuvash Republic",
      "Irkutsk",
      "Ivanovo Oblast",
      "Jewish Autonomous Oblast",
      "Kabardino-Balkar Republic",
      "Kaliningrad",
      "Kaluga Oblast",
      "Kamchatka Krai",
      "Karachay-Cherkess Republic",
      "Kemerovo Oblast",
      "Khabarovsk Krai",
      "Khanty-Mansi Autonomous Okrug",
      "Kirov Oblast",
      "Komi Republic",
      "Kostroma Oblast",
      "Krasnodar Krai",
      "Krasnoyarsk Krai",
      "Kurgan Oblast",
      "Kursk Oblast",
      "Leningrad Oblast",
      "Lipetsk Oblast",
      "Magadan Oblast",
      "Mari El Republic",
      "Moscow",
      "Moscow Oblast",
      "Murmansk Oblast",
      "Nenets Autonomous Okrug",
      "Nizhny Novgorod Oblast",
      "Novgorod Oblast",
      "Novosibirsk",
      "Omsk Oblast",
      "Orenburg Oblast",
      "Oryol Oblast",
      "Penza Oblast",
      "Perm Krai",
      "Primorsky Krai",
      "Pskov Oblast",
      "Republic of Adygea",
      "Republic of Bashkortostan",
      "Republic of Buryatia",
      "Republic of Dagestan",
      "Republic of Ingushetia",
      "Republic of Kalmykia",
      "Republic of Karelia",
      "Republic of Khakassia",
      "Republic of Mordovia",
      "Republic of North Ossetia-Alania",
      "Republic of Tatarstan",
      "Rostov Oblast",
      "Ryazan Oblast",
      "Saint Petersburg",
      "Sakha Republic",
      "Sakhalin",
      "Samara Oblast",
      "Saratov Oblast",
      "Sevastopol",
      "Smolensk Oblast",
      "Stavropol Krai",
      "Sverdlovsk",
      "Tambov Oblast",
      "Tomsk Oblast",
      "Tula Oblast",
      "Tuva Republic",
      "Tver Oblast",
      "Tyumen Oblast",
      "Udmurt Republic",
      "Ulyanovsk Oblast",
      "Vladimir Oblast",
      "Vologda Oblast",
      "Voronezh Oblast",
      "Yamalo-Nenets Autonomous Okrug",
      "Yaroslavl Oblast",
      "Zabaykalsky Krai"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg",
    "dial": "+7",
    "code": "RU"
  },
  {
    "name": "Rwanda",
    "states": [
      "Eastern Province",
      "Kigali district",
      "Northern Province",
      "Southern Province",
      "Western Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg",
    "dial": "+250",
    "code": "RW"
  },
  {
    "name": "Saint Kitts And Nevis",
    "states": [
      "Christ Church Nichola Town Parish",
      "Nevis",
      "Saint Anne Sandy Point Parish",
      "Saint George Gingerland Parish",
      "Saint James Windward Parish",
      "Saint John Capisterre Parish",
      "Saint John Figtree Parish",
      "Saint Kitts",
      "Saint Mary Cayon Parish",
      "Saint Paul Capisterre Parish",
      "Saint Paul Charlestown Parish",
      "Saint Peter Basseterre Parish",
      "Saint Thomas Lowland Parish",
      "Saint Thomas Middle Island Parish",
      "Trinity Palmetto Point Parish"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg",
    "dial": "+1 869",
    "code": "KN"
  },
  {
    "name": "Saint Lucia",
    "states": [
      "Anse la Raye Quarter",
      "Canaries",
      "Castries Quarter",
      "Choiseul Quarter",
      "Dauphin Quarter",
      "Dennery Quarter",
      "Gros Islet Quarter",
      "Laborie Quarter",
      "Micoud Quarter",
      "Praslin Quarter",
      "Soufrière Quarter",
      "Vieux Fort Quarter"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg",
    "dial": "+1 758",
    "code": "LC"
  },
  {
    "name": "Saint Vincent And The Grenadines",
    "states": [
      "Charlotte Parish",
      "Grenadines Parish",
      "Saint Andrew Parish",
      "Saint David Parish",
      "Saint George Parish",
      "Saint Patrick Parish"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg",
    "dial": "+1 784",
    "code": "VC"
  },
  {
    "name": "Samoa",
    "states": [
      "A'ana",
      "Aiga-i-le-Tai",
      "Atua",
      "Fa'asaleleaga",
      "Gaga'emauga",
      "Gaga'ifomauga",
      "Palauli",
      "Satupa'itea",
      "Tuamasaga",
      "Va'a-o-Fonoti",
      "Vaisigano"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg",
    "dial": "+685",
    "code": "WS"
  },
  {
    "name": "San Marino",
    "states": [
      "Acquaviva",
      "Borgo Maggiore",
      "Chiesanuova",
      "Domagnano",
      "Faetano",
      "Fiorentino",
      "Montegiardino",
      "San Marino",
      "Serravalle"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg",
    "dial": "+378",
    "code": "SM"
  },
  {
    "name": "Sao Tome and Principe",
    "states": [
      "Príncipe Province",
      "São Tomé Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg",
    "dial": "+239",
    "code": "ST"
  },
  {
    "name": "Saudi Arabia",
    "states": [
      "'Asir Region",
      "Al Bahah Region",
      "Al Jawf Region",
      "Al Madinah Region",
      "Al-Qassim Region",
      "Eastern Province",
      "Ha'il Region",
      "Jizan Region",
      "Makkah Region",
      "Najran Region",
      "Northern Borders Region",
      "Riyadh Region",
      "Tabuk Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg",
    "dial": "+966",
    "code": "SA"
  },
  {
    "name": "Senegal",
    "states": [
      "Dakar",
      "Diourbel Region",
      "Fatick",
      "Kaffrine",
      "Kaolack",
      "Kédougou",
      "Kolda",
      "Louga",
      "Matam",
      "Saint-Louis",
      "Sédhiou",
      "Tambacounda Region",
      "Thiès Region",
      "Ziguinchor"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg",
    "dial": "+221",
    "code": "SN"
  },
  {
    "name": "Serbia",
    "states": [
      "Belgrade",
      "Bor District",
      "Braničevo District",
      "Central Banat District",
      "Jablanica District",
      "Kolubara District",
      "Mačva District",
      "Moravica District",
      "Nišava District",
      "North Bačka District",
      "North Banat District",
      "Pčinja District",
      "Pirot District",
      "Podunavlje District",
      "Pomoravlje District",
      "Rasina District",
      "Raška District",
      "South Bačka District",
      "South Banat District",
      "Srem District",
      "Šumadija District",
      "Toplica District",
      "Vojvodina",
      "West Bačka District",
      "Zaječar District",
      "Zlatibor District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg",
    "dial": "+381",
    "code": "RS"
  },
  {
    "name": "Seychelles",
    "states": [
      "Anse Boileau",
      "Anse Royale",
      "Anse-aux-Pins",
      "Au Cap",
      "Baie Lazare",
      "Baie Sainte Anne",
      "Beau Vallon",
      "Bel Air",
      "Bel Ombre",
      "Cascade",
      "Glacis",
      "Grand'Anse Mahé",
      "Grand'Anse Praslin",
      "La Digue",
      "La Rivière Anglaise",
      "Les Mamelles",
      "Mont Buxton",
      "Mont Fleuri",
      "Plaisance",
      "Pointe La Rue",
      "Port Glaud",
      "Roche Caiman",
      "Saint Louis",
      "Takamaka"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg",
    "dial": "+248",
    "code": "SC"
  },
  {
    "name": "Sierra Leone",
    "states": [
      "Eastern Province",
      "Northern Province",
      "Southern Province",
      "Western Area"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg",
    "dial": "+232",
    "code": "SL"
  },
  {
    "name": "Singapore",
    "states": [
      "Central Singapore Community Development Council",
      "North East Community Development Council",
      "North West Community Development Council",
      "South East Community Development Council",
      "South West Community Development Council"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg",
    "dial": "+65",
    "code": "SG"
  },
  {
    "name": "Slovakia",
    "states": [
      "Banská Bystrica Region",
      "Bratislava Region",
      "Košice Region",
      "Nitra Region",
      "Prešov Region",
      "Trenčín Region",
      "Trnava Region",
      "Žilina Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg",
    "dial": "+421",
    "code": "SK"
  },
  {
    "name": "Slovenia",
    "states": [
      "Ajdovščina Municipality",
      "Ankaran Municipality",
      "Beltinci Municipality",
      "Benedikt Municipality",
      "Bistrica ob Sotli Municipality",
      "Bled Municipality",
      "Bloke Municipality",
      "Bohinj Municipality",
      "Borovnica Municipality",
      "Bovec Municipality",
      "Braslovče Municipality",
      "Brda Municipality",
      "Brežice Municipality",
      "Brezovica Municipality",
      "Cankova Municipality",
      "Cerklje na Gorenjskem Municipality",
      "Cerknica Municipality",
      "Cerkno Municipality",
      "Cerkvenjak Municipality",
      "City Municipality of Celje",
      "City Municipality of Novo Mesto",
      "Črenšovci Municipality",
      "Črna na Koroškem Municipality",
      "Črnomelj Municipality",
      "Destrnik Municipality",
      "Divača Municipality",
      "Dobje Municipality",
      "Dobrepolje Municipality",
      "Dobrna Municipality",
      "Dobrova–Polhov Gradec Municipality",
      "Dobrovnik Municipality",
      "Dol pri Ljubljani Municipality",
      "Dolenjske Toplice Municipality",
      "Domžale Municipality",
      "Dornava Municipality",
      "Dravograd Municipality",
      "Duplek Municipality",
      "Gorenja Vas–Poljane Municipality",
      "Gorišnica Municipality",
      "Gorje Municipality",
      "Gornja Radgona Municipality",
      "Gornji Grad Municipality",
      "Gornji Petrovci Municipality",
      "Grad Municipality",
      "Grosuplje Municipality",
      "Hajdina Municipality",
      "Hoče–Slivnica Municipality",
      "Hodoš Municipality",
      "Horjul Municipality",
      "Hrastnik Municipality",
      "Hrpelje–Kozina Municipality",
      "Idrija Municipality",
      "Ig Municipality",
      "Ivančna Gorica Municipality",
      "Izola Municipality",
      "Jesenice Municipality",
      "Jezersko Municipality",
      "Juršinci Municipality",
      "Kamnik Municipality",
      "Kanal ob Soči Municipality",
      "Kidričevo Municipality",
      "Kobarid Municipality",
      "Kobilje Municipality",
      "Kočevje Municipality",
      "Komen Municipality",
      "Komenda Municipality",
      "Koper City Municipality",
      "Kostanjevica na Krki Municipality",
      "Kostel Municipality",
      "Kozje Municipality",
      "Kranj City Municipality",
      "Kranjska Gora Municipality",
      "Križevci Municipality",
      "Kungota",
      "Kuzma Municipality",
      "Laško Municipality",
      "Lenart Municipality",
      "Lendava Municipality",
      "Litija Municipality",
      "Ljubljana City Municipality",
      "Ljubno Municipality",
      "Ljutomer Municipality",
      "Log–Dragomer Municipality",
      "Logatec Municipality",
      "Loška Dolina Municipality",
      "Loški Potok Municipality",
      "Lovrenc na Pohorju Municipality",
      "Luče Municipality",
      "Lukovica Municipality",
      "Majšperk Municipality",
      "Makole Municipality",
      "Maribor City Municipality",
      "Markovci Municipality",
      "Medvode Municipality",
      "Mengeš Municipality",
      "Metlika Municipality",
      "Mežica Municipality",
      "Miklavž na Dravskem Polju Municipality",
      "Miren–Kostanjevica Municipality",
      "Mirna Municipality",
      "Mirna Peč Municipality",
      "Mislinja Municipality",
      "Mokronog–Trebelno Municipality",
      "Moravče Municipality",
      "Moravske Toplice Municipality",
      "Mozirje Municipality",
      "Municipality of Apače",
      "Municipality of Cirkulane",
      "Municipality of Ilirska Bistrica",
      "Municipality of Krško",
      "Municipality of Škofljica",
      "Murska Sobota City Municipality",
      "Muta Municipality",
      "Naklo Municipality",
      "Nazarje Municipality",
      "Nova Gorica City Municipality",
      "Odranci Municipality",
      "Oplotnica",
      "Ormož Municipality",
      "Osilnica Municipality",
      "Pesnica Municipality",
      "Piran Municipality",
      "Pivka Municipality",
      "Podčetrtek Municipality",
      "Podlehnik Municipality",
      "Podvelka Municipality",
      "Poljčane Municipality",
      "Polzela Municipality",
      "Postojna Municipality",
      "Prebold Municipality",
      "Preddvor Municipality",
      "Prevalje Municipality",
      "Ptuj City Municipality",
      "Puconci Municipality",
      "Rače–Fram Municipality",
      "Radeče Municipality",
      "Radenci Municipality",
      "Radlje ob Dravi Municipality",
      "Radovljica Municipality",
      "Ravne na Koroškem Municipality",
      "Razkrižje Municipality",
      "Rečica ob Savinji Municipality",
      "Renče–Vogrsko Municipality",
      "Ribnica Municipality",
      "Ribnica na Pohorju Municipality",
      "Rogaška Slatina Municipality",
      "Rogašovci Municipality",
      "Rogatec Municipality",
      "Ruše Municipality",
      "Šalovci Municipality",
      "Selnica ob Dravi Municipality",
      "Semič Municipality",
      "Šempeter–Vrtojba Municipality",
      "Šenčur Municipality",
      "Šentilj Municipality",
      "Šentjernej Municipality",
      "Šentjur Municipality",
      "Šentrupert Municipality",
      "Sevnica Municipality",
      "Sežana Municipality",
      "Škocjan Municipality",
      "Škofja Loka Municipality",
      "Slovenj Gradec City Municipality",
      "Slovenska Bistrica Municipality",
      "Slovenske Konjice Municipality",
      "Šmarje pri Jelšah Municipality",
      "Šmarješke Toplice Municipality",
      "Šmartno ob Paki Municipality",
      "Šmartno pri Litiji Municipality",
      "Sodražica Municipality",
      "Solčava Municipality",
      "Šoštanj Municipality",
      "Središče ob Dravi",
      "Starše Municipality",
      "Štore Municipality",
      "Straža Municipality",
      "Sveta Ana Municipality",
      "Sveta Trojica v Slovenskih Goricah Municipality",
      "Sveti Andraž v Slovenskih Goricah Municipality",
      "Sveti Jurij ob Ščavnici Municipality",
      "Sveti Jurij v Slovenskih Goricah Municipality",
      "Sveti Tomaž Municipality",
      "Tabor Municipality",
      "Tišina Municipality",
      "Tolmin Municipality",
      "Trbovlje Municipality",
      "Trebnje Municipality",
      "Trnovska Vas Municipality",
      "Tržič Municipality",
      "Trzin Municipality",
      "Turnišče Municipality",
      "Velika Polana Municipality",
      "Velike Lašče Municipality",
      "Veržej Municipality",
      "Videm Municipality",
      "Vipava Municipality",
      "Vitanje Municipality",
      "Vodice Municipality",
      "Vojnik Municipality",
      "Vransko Municipality",
      "Vrhnika Municipality",
      "Vuzenica Municipality",
      "Zagorje ob Savi Municipality",
      "Žalec Municipality",
      "Zavrč Municipality",
      "Železniki Municipality",
      "Žetale Municipality",
      "Žiri Municipality",
      "Žirovnica Municipality",
      "Zreče Municipality",
      "Žužemberk Municipality"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg",
    "dial": "+386",
    "code": "SI"
  },
  {
    "name": "Solomon Islands",
    "states": [
      "Central Province",
      "Choiseul Province",
      "Guadalcanal Province",
      "Honiara",
      "Isabel Province",
      "Makira-Ulawa Province",
      "Malaita Province",
      "Rennell and Bellona Province",
      "Temotu Province",
      "Western Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg",
    "dial": "+677",
    "code": "SB"
  },
  {
    "name": "Somalia",
    "states": [
      "Awdal Region",
      "Bakool",
      "Banaadir",
      "Bari",
      "Bay",
      "Galguduud",
      "Gedo",
      "Hiran",
      "Lower Juba",
      "Lower Shebelle",
      "Middle Juba",
      "Middle Shebelle",
      "Mudug",
      "Nugal",
      "Sanaag Region",
      "Togdheer Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg",
    "dial": "+252",
    "code": "SO"
  },
  {
    "name": "South Africa",
    "states": [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "North West",
      "Northern Cape",
      "Western Cape"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg",
    "dial": "+27",
    "code": "ZA"
  },
  {
    "name": "Spain",
    "states": [
      "A Coruña Province",
      "Albacete Province",
      "Alicante Province",
      "Almería Province",
      "Andalusia",
      "Araba Álava Province",
      "Aragon",
      "Badajoz Province",
      "Balearic Islands",
      "Barcelona Province",
      "Basque Country",
      "Biscay Province",
      "Burgos Province",
      "Cáceres Province",
      "Cádiz Province",
      "Canary Islands",
      "Cantabria",
      "Castellón Province",
      "Castile and León",
      "Castile-La Mancha",
      "Catalonia",
      "Ceuta",
      "Ciudad Real Province",
      "Community of Madrid",
      "Córdoba Province",
      "Cuenca Province",
      "Extremadura",
      "Galicia",
      "Gipuzkoa Province",
      "Girona Province",
      "Granada Province",
      "Guadalajara Province",
      "Huelva Province",
      "Huesca Province",
      "Jaén Province",
      "La Rioja",
      "Las Palmas Province",
      "León Province",
      "Lleida Province",
      "Lugo Province",
      "Madrid Province",
      "Málaga Province",
      "Melilla",
      "Murcia Province",
      "Navarre",
      "Ourense Province",
      "Palencia Province",
      "Pontevedra Province",
      "Province of Asturias",
      "Province of Ávila",
      "Region of Murcia",
      "Salamanca Province",
      "Santa Cruz de Tenerife Province",
      "Segovia Province",
      "Seville Province",
      "Soria Province",
      "Tarragona Province",
      "Teruel Province",
      "Toledo Province",
      "Valencia Province",
      "Valencian Community",
      "Valladolid Province",
      "Zamora Province",
      "Zaragoza Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg",
    "dial": "+34",
    "code": "ES"
  },
  {
    "name": "Sri Lanka",
    "states": [
      "Ampara District",
      "Anuradhapura District",
      "Badulla District",
      "Batticaloa District",
      "Central Province",
      "Colombo District",
      "Eastern Province",
      "Galle District",
      "Gampaha District",
      "Hambantota District",
      "Jaffna District",
      "Kalutara District",
      "Kandy District",
      "Kegalle District",
      "Kilinochchi District",
      "Mannar District",
      "Matale District",
      "Matara District",
      "Monaragala District",
      "Mullaitivu District",
      "North Central Province",
      "North Western Province",
      "Northern Province",
      "Nuwara Eliya District",
      "Polonnaruwa District",
      "Puttalam District",
      "Ratnapura district",
      "Sabaragamuwa Province",
      "Southern Province",
      "Trincomalee District",
      "Uva Province",
      "Vavuniya District",
      "Western Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg",
    "dial": "+94",
    "code": "LK"
  },
  {
    "name": "Sudan",
    "states": [
      "Al Jazirah",
      "Al Qadarif",
      "Blue Nile",
      "Central Darfur",
      "East Darfur",
      "Kassala",
      "Khartoum",
      "North Darfur",
      "North Kordofan",
      "Northern",
      "Red Sea",
      "River Nile",
      "Sennar",
      "South Darfur",
      "South Kordofan",
      "West Darfur",
      "West Kordofan",
      "White Nile"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg",
    "dial": "+249",
    "code": "SD"
  },
  {
    "name": "Suriname",
    "states": [
      "Brokopondo District",
      "Commewijne District",
      "Coronie District",
      "Marowijne District",
      "Nickerie District",
      "Para District",
      "Paramaribo District",
      "Saramacca District",
      "Sipaliwini District",
      "Wanica District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg",
    "dial": "+597",
    "code": "SR"
  },
  {
    "name": "Swaziland",
    "states": [
      "Hhohho District",
      "Lubombo District",
      "Manzini District",
      "Shiselweni District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg",
    "dial": "+268",
    "code": "SZ"
  },
  {
    "name": "Sweden",
    "states": [
      "Blekinge",
      "Dalarna County",
      "Gävleborg County",
      "Gotland County",
      "Halland County",
      "Jönköping County",
      "Kalmar County",
      "Kronoberg County",
      "Norrbotten County",
      "Örebro County",
      "Östergötland County",
      "Skåne County",
      "Södermanland County",
      "Stockholm County",
      "Uppsala County",
      "Värmland County",
      "Västerbotten County",
      "Västernorrland County",
      "Västmanland County",
      "Västra Götaland County"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg",
    "dial": "+46",
    "code": "SE"
  },
  {
    "name": "Switzerland",
    "states": [
      "Aargau",
      "Appenzell Ausserrhoden",
      "Appenzell Innerrhoden",
      "Basel-Landschaft",
      "canton of Bern",
      "Canton of Fribourg",
      "Canton of Geneva",
      "Canton of Jura",
      "Canton of Lucerne",
      "Canton of Neuchâtel",
      "Canton of Schaffhausen",
      "Canton of Solothurn",
      "Canton of St. Gallen",
      "Canton of Valais",
      "Canton of Vaud",
      "Canton of Zug",
      "canton of Zürich",
      "Glarus",
      "Graubünden",
      "Nidwalden",
      "Obwalden",
      "Schwyz",
      "Thurgau",
      "Ticino",
      "Uri"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg",
    "dial": "+41",
    "code": "CH"
  },
  {
    "name": "Syria",
    "states": [
      "Al-Hasakah Governorate",
      "Al-Raqqah Governorate",
      "Aleppo Governorate",
      "As-Suwayda Governorate",
      "Damascus Governorate",
      "Daraa Governorate",
      "Deir ez-Zor Governorate",
      "Hama Governorate",
      "Homs Governorate",
      "Idlib Governorate",
      "Latakia Governorate",
      "Quneitra Governorate",
      "Rif Dimashq Governorate",
      "Tartus Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg",
    "dial": "+963",
    "code": "SY"
  },
  {
    "name": "Taiwan",
    "states": [
      "Changhua County",
      "Chiayi City",
      "Chiayi County",
      "Hsinchu",
      "Hsinchu County",
      "Hualien County",
      "Kaohsiung",
      "Kaohsiung County",
      "Kinmen",
      "Lienchiang County",
      "Miaoli County",
      "Nantou County",
      "Penghu County",
      "Pingtung County",
      "Taichung",
      "Taichung County",
      "Tainan",
      "Tainan County",
      "Taipei",
      "Taitung County",
      "Taoyuan City",
      "Yilan County",
      "Yunlin County"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg",
    "dial": "+886",
    "code": "TW"
  },
  {
    "name": "Tajikistan",
    "states": [
      "districts of Republican Subordination",
      "Gorno-Badakhshan Autonomous Province",
      "Khatlon Province",
      "Sughd Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg",
    "dial": "+992",
    "code": "TJ"
  },
  {
    "name": "Tanzania",
    "states": [
      "Arusha Region",
      "Dar es Salaam Region",
      "Dodoma Region",
      "Geita Region",
      "Iringa Region",
      "Kagera Region",
      "Katavi Region",
      "Kigoma Region",
      "Kilimanjaro Region",
      "Lindi Region",
      "Manyara Region",
      "Mara Region",
      "Morogoro Region",
      "Mtwara Region",
      "Mwanza Region",
      "Njombe Region",
      "North Pemba Region",
      "Pwani Region",
      "Rukwa Region",
      "Ruvuma Region",
      "Shinyanga Region",
      "Simiyu Region",
      "Singida Region",
      "South Pemba Region",
      "Tabora Region",
      "Tanga Region",
      "Zanzibar Central South Region",
      "Zanzibar North Region",
      "Zanzibar Urban West Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg",
    "dial": "+255",
    "code": "TZ"
  },
  {
    "name": "Thailand",
    "states": [
      "Amnat Charoen",
      "Ang Thong",
      "Bangkok",
      "Bueng Kan",
      "Buri Ram",
      "Chachoengsao",
      "Chai Nat",
      "Chanthaburi",
      "Chiang Mai",
      "Chiang Rai",
      "Chon Buri",
      "Chumphon",
      "Kalasin",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Khon Kaen",
      "Krabi",
      "Lampang",
      "Lamphun",
      "Loei",
      "Lopburi",
      "Mae Hong Son",
      "Maha Sarakham",
      "Mukdahan",
      "Nakhon Nayok",
      "Nakhon Pathom",
      "Nakhon Phanom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nan",
      "Narathiwat",
      "Nong Bua Lam Phu",
      "Nong Khai",
      "Nonthaburi",
      "Pathum Thani",
      "Pattani",
      "Pattaya",
      "Phang Nga",
      "Phatthalung",
      "Phayao",
      "Phetchabun",
      "Phetchaburi",
      "Phichit",
      "Phitsanulok",
      "Phra Nakhon Si Ayutthaya",
      "Phrae",
      "Phuket",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Ranong",
      "Ratchaburi",
      "Rayong",
      "Roi Et",
      "Sa Kaeo",
      "Sakon Nakhon",
      "Samut Prakan",
      "Samut Sakhon",
      "Samut Songkhram",
      "Saraburi",
      "Satun",
      "Si Sa Ket",
      "Sing Buri",
      "Songkhla",
      "Sukhothai",
      "Suphanburi",
      "Surat Thani",
      "Surin",
      "Tak",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Yala",
      "Yasothon"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg",
    "dial": "+66",
    "code": "TH"
  },
  {
    "name": "Togo",
    "states": [
      "Centrale Region",
      "Kara Region",
      "Maritime",
      "Plateaux Region",
      "Savanes Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg",
    "dial": "+228",
    "code": "TG"
  },
  {
    "name": "Tonga",
    "states": [
      "Haʻapai",
      "ʻEua",
      "Niuas",
      "Tongatapu",
      "Vavaʻu"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg",
    "dial": "+676",
    "code": "TO"
  },
  {
    "name": "Trinidad And Tobago",
    "states": [
      "Arima",
      "Chaguanas",
      "Couva-Tabaquite-Talparo Regional Corporation",
      "Diego Martin Regional Corporation",
      "Eastern Tobago",
      "Penal-Debe Regional Corporation",
      "Point Fortin",
      "Port of Spain",
      "Princes Town Regional Corporation",
      "Rio Claro-Mayaro Regional Corporation",
      "San Fernando",
      "San Juan-Laventille Regional Corporation",
      "Sangre Grande Regional Corporation",
      "Siparia Regional Corporation",
      "Tunapuna-Piarco Regional Corporation",
      "Western Tobago"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg",
    "dial": "+1 868",
    "code": "TT"
  },
  {
    "name": "Tunisia",
    "states": [
      "Ariana Governorate",
      "Ben Arous Governorate",
      "Bizerte Governorate",
      "Gabès Governorate",
      "Gafsa Governorate",
      "Jendouba Governorate",
      "Kairouan Governorate",
      "Kasserine Governorate",
      "Kassrine",
      "Kebili Governorate",
      "Kef Governorate",
      "Mahdia Governorate",
      "Manouba Governorate",
      "Medenine Governorate",
      "Monastir Governorate",
      "Sfax Governorate",
      "Sidi Bouzid Governorate",
      "Siliana Governorate",
      "Sousse Governorate",
      "Tataouine Governorate",
      "Tozeur Governorate",
      "Tunis Governorate",
      "Zaghouan Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg",
    "dial": "+216",
    "code": "TN"
  },
  {
    "name": "Turkey",
    "states": [
      "Adana Province",
      "Adıyaman Province",
      "Afyonkarahisar Province",
      "Ağrı Province",
      "Aksaray Province",
      "Amasya Province",
      "Ankara Province",
      "Antalya Province",
      "Ardahan Province",
      "Artvin Province",
      "Aydın Province",
      "Balıkesir Province",
      "Bartın Province",
      "Batman Province",
      "Bayburt Province",
      "Bilecik Province",
      "Bingöl Province",
      "Bitlis Province",
      "Bolu Province",
      "Burdur Province",
      "Bursa Province",
      "Çanakkale Province",
      "Çankırı Province",
      "Çorum Province",
      "Denizli Province",
      "Diyarbakır Province",
      "Düzce Province",
      "Edirne Province",
      "Elazığ Province",
      "Erzincan Province",
      "Erzurum Province",
      "Eskişehir Province",
      "Gaziantep Province",
      "Giresun Province",
      "Gümüşhane Province",
      "Hakkâri Province",
      "Hatay Province",
      "Iğdır Province",
      "Isparta Province",
      "Istanbul Province",
      "İzmir Province",
      "Kahramanmaraş Province",
      "Karabük Province",
      "Karaman Province",
      "Kars Province",
      "Kastamonu Province",
      "Kayseri Province",
      "Kilis Province",
      "Kırıkkale Province",
      "Kırklareli Province",
      "Kırşehir Province",
      "Kocaeli Province",
      "Konya Province",
      "Kütahya Province",
      "Malatya Province",
      "Manisa Province",
      "Mardin Province",
      "Mersin Province",
      "Muğla Province",
      "Muş Province",
      "Nevşehir Province",
      "Niğde Province",
      "Ordu Province",
      "Osmaniye Province",
      "Rize Province",
      "Sakarya Province",
      "Samsun Province",
      "Şanlıurfa Province",
      "Siirt Province",
      "Sinop Province",
      "Sivas Province",
      "Şırnak Province",
      "Tekirdağ Province",
      "Tokat Province",
      "Trabzon Province",
      "Tunceli Province",
      "Uşak Province",
      "Van Province",
      "Yalova Province",
      "Yozgat Province",
      "Zonguldak Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg",
    "dial": "+90",
    "code": "TR"
  },
  {
    "name": "Turkmenistan",
    "states": [
      "Ahal Region",
      "Ashgabat",
      "Balkan Region",
      "Daşoguz Region",
      "Lebap Region",
      "Mary Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg",
    "dial": "+993",
    "code": "TM"
  },
  {
    "name": "Tuvalu",
    "states": [
      "Funafuti",
      "Nanumanga",
      "Nanumea",
      "Niutao Island Council",
      "Nui",
      "Nukufetau",
      "Nukulaelae",
      "Vaitupu"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg",
    "dial": "+688",
    "code": "TV"
  },
  {
    "name": "Uganda",
    "states": [
      "Abim District",
      "Adjumani District",
      "Agago District",
      "Alebtong District",
      "Amolatar District",
      "Amudat District",
      "Amuria District",
      "Amuru District",
      "Apac District",
      "Arua District",
      "Budaka District",
      "Bududa District",
      "Bugiri District",
      "Buhweju District",
      "Buikwe District",
      "Bukedea District",
      "Bukomansimbi District",
      "Bukwo District",
      "Bulambuli District",
      "Buliisa District",
      "Bundibugyo District",
      "Bunyangabu District",
      "Bushenyi District",
      "Busia District",
      "Butaleja District",
      "Butambala District",
      "Butebo District",
      "Buvuma District",
      "Buyende District",
      "Central Region",
      "Dokolo District",
      "Eastern Region",
      "Gomba District",
      "Gulu District",
      "Ibanda District",
      "Iganga District",
      "Isingiro District",
      "Jinja District",
      "Kaabong District",
      "Kabale District",
      "Kabarole District",
      "Kaberamaido District",
      "Kagadi District",
      "Kakumiro District",
      "Kalangala District",
      "Kaliro District",
      "Kalungu District",
      "Kampala District",
      "Kamuli District",
      "Kamwenge District",
      "Kanungu District",
      "Kapchorwa District",
      "Kasese District",
      "Katakwi District",
      "Kayunga District",
      "Kibaale District",
      "Kiboga District",
      "Kibuku District",
      "Kiruhura District",
      "Kiryandongo District",
      "Kisoro District",
      "Kitgum District",
      "Koboko District",
      "Kole District",
      "Kotido District",
      "Kumi District",
      "Kween District",
      "Kyankwanzi District",
      "Kyegegwa District",
      "Kyenjojo District",
      "Kyotera District",
      "Lamwo District",
      "Lira District",
      "Luuka District",
      "Luwero District",
      "Lwengo District",
      "Lyantonde District",
      "Manafwa District",
      "Maracha District",
      "Masaka District",
      "Masindi District",
      "Mayuge District",
      "Mbale District",
      "Mbarara District",
      "Mitooma District",
      "Mityana District",
      "Moroto District",
      "Moyo District",
      "Mpigi District",
      "Mubende District",
      "Mukono District",
      "Nakapiripirit District",
      "Nakaseke District",
      "Nakasongola District",
      "Namayingo District",
      "Namisindwa District",
      "Namutumba District",
      "Napak District",
      "Nebbi District",
      "Ngora District",
      "Northern Region",
      "Ntoroko District",
      "Ntungamo District",
      "Nwoya District",
      "Omoro District",
      "Otuke District",
      "Oyam District",
      "Pader District",
      "Pakwach District",
      "Pallisa District",
      "Rakai District",
      "Rubanda District",
      "Rubirizi District",
      "Rukiga District",
      "Rukungiri District",
      "Sembabule District",
      "Serere District",
      "Sheema District",
      "Sironko District",
      "Soroti District",
      "Tororo District",
      "Wakiso District",
      "Western Region",
      "Yumbe District",
      "Zombo District"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg",
    "dial": "+256",
    "code": "UG"
  },
  {
    "name": "Ukraine",
    "states": [
      "Autonomous Republic of Crimea",
      "Cherkasy Oblast",
      "Chernihiv Oblast",
      "Chernivtsi Oblast",
      "Dnipropetrovsk Oblast",
      "Donetsk Oblast",
      "Ivano-Frankivsk Oblast",
      "Kharkiv Oblast",
      "Kherson Oblast",
      "Khmelnytsky Oblast",
      "Kiev",
      "Kirovohrad Oblast",
      "Kyiv Oblast",
      "Luhansk Oblast",
      "Lviv Oblast",
      "Mykolaiv Oblast",
      "Odessa Oblast",
      "Rivne Oblast",
      "Sumy Oblast",
      "Ternopil Oblast",
      "Vinnytsia Oblast",
      "Volyn Oblast",
      "Zakarpattia Oblast",
      "Zaporizhzhya Oblast",
      "Zhytomyr Oblast"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg",
    "dial": "+380",
    "code": "UA"
  },
  {
    "name": "United Arab Emirates",
    "states": [
      "Abu Dhabi Emirate",
      "Ajman Emirate",
      "Dubai",
      "Fujairah",
      "Ras al-Khaimah",
      "Sharjah Emirate",
      "Umm al-Quwain"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg",
    "dial": "+971",
    "code": "AE"
  },
  {
    "name": "United Kingdom",
    "states": [
      "Aberdeen",
      "Aberdeenshire",
      "Angus",
      "Antrim",
      "Antrim and Newtownabbey",
      "Ards",
      "Ards and North Down",
      "Argyll and Bute",
      "Armagh City and District Council",
      "Armagh, Banbridge and Craigavon",
      "Ascension Island",
      "Ballymena Borough",
      "Ballymoney",
      "Banbridge",
      "Barnsley",
      "Bath and North East Somerset",
      "Bedford",
      "Belfast district",
      "Birmingham",
      "Blackburn with Darwen",
      "Blackpool",
      "Blaenau Gwent County Borough",
      "Bolton",
      "Bournemouth",
      "Bracknell Forest",
      "Bradford",
      "Bridgend County Borough",
      "Brighton and Hove",
      "Buckinghamshire",
      "Bury",
      "Caerphilly County Borough",
      "Calderdale",
      "Cambridgeshire",
      "Carmarthenshire",
      "Carrickfergus Borough Council",
      "Castlereagh",
      "Causeway Coast and Glens",
      "Central Bedfordshire",
      "Ceredigion",
      "Cheshire East",
      "Cheshire West and Chester",
      "City and County of Cardiff",
      "City and County of Swansea",
      "City of Bristol",
      "City of Derby",
      "City of Kingston upon Hull",
      "City of Leicester",
      "City of London",
      "City of Nottingham",
      "City of Peterborough",
      "City of Plymouth",
      "City of Portsmouth",
      "City of Southampton",
      "City of Stoke-on-Trent",
      "City of Sunderland",
      "City of Westminster",
      "City of Wolverhampton",
      "City of York",
      "Clackmannanshire",
      "Coleraine Borough Council",
      "Conwy County Borough",
      "Cookstown District Council",
      "Cornwall",
      "County Durham",
      "Coventry",
      "Craigavon Borough Council",
      "Cumbria",
      "Darlington",
      "Denbighshire",
      "Derbyshire",
      "Derry City and Strabane",
      "Derry City Council",
      "Devon",
      "Doncaster",
      "Dorset",
      "Down District Council",
      "Dudley",
      "Dumfries and Galloway",
      "Dundee",
      "Dungannon and South Tyrone Borough Council",
      "East Ayrshire",
      "East Dunbartonshire",
      "East Lothian",
      "East Renfrewshire",
      "East Riding of Yorkshire",
      "East Sussex",
      "Edinburgh",
      "England",
      "Essex",
      "Falkirk",
      "Fermanagh and Omagh",
      "Fermanagh District Council",
      "Fife",
      "Flintshire",
      "Gateshead",
      "Glasgow",
      "Gloucestershire",
      "Gwynedd",
      "Halton",
      "Hampshire",
      "Hartlepool",
      "Herefordshire",
      "Hertfordshire",
      "Highland",
      "Inverclyde",
      "Isle of Wight",
      "Isles of Scilly",
      "Kent",
      "Kirklees",
      "Knowsley",
      "Lancashire",
      "Larne Borough Council",
      "Leeds",
      "Leicestershire",
      "Limavady Borough Council",
      "Lincolnshire",
      "Lisburn and Castlereagh",
      "Lisburn City Council",
      "Liverpool",
      "London Borough of Barking and Dagenham",
      "London Borough of Barnet",
      "London Borough of Bexley",
      "London Borough of Brent",
      "London Borough of Bromley",
      "London Borough of Camden",
      "London Borough of Croydon",
      "London Borough of Ealing",
      "London Borough of Enfield",
      "London Borough of Hackney",
      "London Borough of Hammersmith and Fulham",
      "London Borough of Haringey",
      "London Borough of Harrow",
      "London Borough of Havering",
      "London Borough of Hillingdon",
      "London Borough of Hounslow",
      "London Borough of Islington",
      "London Borough of Lambeth",
      "London Borough of Lewisham",
      "London Borough of Merton",
      "London Borough of Newham",
      "London Borough of Redbridge",
      "London Borough of Richmond upon Thames",
      "London Borough of Southwark",
      "London Borough of Sutton",
      "London Borough of Tower Hamlets",
      "London Borough of Waltham Forest",
      "London Borough of Wandsworth",
      "Magherafelt District Council",
      "Manchester",
      "Medway",
      "Merthyr Tydfil County Borough",
      "Metropolitan Borough of Wigan",
      "Mid and East Antrim",
      "Mid Ulster",
      "Middlesbrough",
      "Midlothian",
      "Milton Keynes",
      "Monmouthshire",
      "Moray",
      "Moyle District Council",
      "Neath Port Talbot County Borough",
      "Newcastle upon Tyne",
      "Newport",
      "Newry and Mourne District Council",
      "Newry, Mourne and Down",
      "Newtownabbey Borough Council",
      "Norfolk",
      "North Ayrshire",
      "North Down Borough Council",
      "North East Lincolnshire",
      "North Lanarkshire",
      "North Lincolnshire",
      "North Somerset",
      "North Tyneside",
      "North Yorkshire",
      "Northamptonshire",
      "Northern Ireland",
      "Northumberland",
      "Nottinghamshire",
      "Oldham",
      "Omagh District Council",
      "Orkney Islands",
      "Outer Hebrides",
      "Oxfordshire",
      "Pembrokeshire",
      "Perth and Kinross",
      "Poole",
      "Powys",
      "Reading",
      "Redcar and Cleveland",
      "Renfrewshire",
      "Rhondda Cynon Taf",
      "Rochdale",
      "Rotherham",
      "Royal Borough of Greenwich",
      "Royal Borough of Kensington and Chelsea",
      "Royal Borough of Kingston upon Thames",
      "Rutland",
      "Saint Helena",
      "Salford",
      "Sandwell",
      "Scotland",
      "Scottish Borders",
      "Sefton",
      "Sheffield",
      "Shetland Islands",
      "Shropshire",
      "Slough",
      "Solihull",
      "Somerset",
      "South Ayrshire",
      "South Gloucestershire",
      "South Lanarkshire",
      "South Tyneside",
      "Southend-on-Sea",
      "St Helens",
      "Staffordshire",
      "Stirling",
      "Stockport",
      "Stockton-on-Tees",
      "Strabane District Council",
      "Suffolk",
      "Surrey",
      "Swindon",
      "Tameside",
      "Telford and Wrekin",
      "Thurrock",
      "Torbay",
      "Torfaen",
      "Trafford",
      "United Kingdom",
      "Vale of Glamorgan",
      "Wakefield",
      "Wales",
      "Walsall",
      "Warrington",
      "Warwickshire",
      "West Berkshire",
      "West Dunbartonshire",
      "West Lothian",
      "West Sussex",
      "Wiltshire",
      "Windsor and Maidenhead",
      "Wirral",
      "Wokingham",
      "Worcestershire",
      "Wrexham County Borough"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
    "dial": "+44",
    "code": "GB"
  },
  {
    "name": "United States",
    "states": [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "Baker Island",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Howland Island",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Jarvis Island",
      "Johnston Atoll",
      "Kansas",
      "Kentucky",
      "Kingman Reef",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Midway Atoll",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Navassa Island",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palmyra Atoll",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "United States Minor Outlying Islands",
      "United States Virgin Islands",
      "Utah",
      "Vermont",
      "Virginia",
      "Wake Island",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
    "dial": "+1",
    "code": "US"
  },
  {
    "name": "Uruguay",
    "states": [
      "Artigas Department",
      "Canelones Department",
      "Cerro Largo Department",
      "Colonia Department",
      "Durazno Department",
      "Flores Department",
      "Florida Department",
      "Lavalleja Department",
      "Maldonado Department",
      "Montevideo Department",
      "Paysandú Department",
      "Río Negro Department",
      "Rivera Department",
      "Rocha Department",
      "Salto Department",
      "San José Department",
      "Soriano Department",
      "Tacuarembó Department",
      "Treinta y Tres Department"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg",
    "dial": "+598",
    "code": "UY"
  },
  {
    "name": "Uzbekistan",
    "states": [
      "Andijan Region",
      "Bukhara Region",
      "Fergana Region",
      "Jizzakh Region",
      "Karakalpakstan",
      "Namangan Region",
      "Navoiy Region",
      "Qashqadaryo Region",
      "Samarqand Region",
      "Sirdaryo Region",
      "Surxondaryo Region",
      "Tashkent",
      "Tashkent Region",
      "Xorazm Region"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg",
    "dial": "+998",
    "code": "UZ"
  },
  {
    "name": "Vanuatu",
    "states": [
      "Malampa",
      "Penama",
      "Sanma",
      "Shefa",
      "Tafea",
      "Torba"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg",
    "dial": "+678",
    "code": "VU"
  },
  {
    "name": "Venezuela",
    "states": [
      "Amazonas",
      "Anzoátegui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bolívar",
      "Capital District",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Falcón",
      "Federal Dependencies of Venezuela",
      "Guárico",
      "Lara",
      "Mérida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "Táchira",
      "Trujillo",
      "Vargas",
      "Yaracuy",
      "Zulia"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg",
    "dial": "+58",
    "code": "VE"
  },
  {
    "name": "Vietnam",
    "states": [
      "An Giang",
      "Bà Rịa-Vũng Tàu",
      "Bắc Giang",
      "Bắc Kạn",
      "Bạc Liêu",
      "Bắc Ninh",
      "Bến Tre",
      "Bình Dương",
      "Bình Định",
      "Bình Phước",
      "Bình Thuận",
      "Cà Mau",
      "Cao Bằng",
      "Da Nang",
      "Đắk Lắk",
      "Đắk Nông",
      "Điện Biên",
      "Đồng Nai",
      "Đồng Tháp",
      "Gia Lai",
      "Hà Giang",
      "Hà Nam",
      "Hà Tây",
      "Hà Tĩnh",
      "Hải Dương",
      "Haiphong",
      "Hanoi",
      "Hậu Giang",
      "Ho Chi Minh City",
      "Hòa Bình",
      "Hưng Yên",
      "Khánh Hòa",
      "Kiên Giang",
      "Kon Tum",
      "Lai Châu",
      "Lâm Đồng",
      "Lạng Sơn",
      "Lào Cai",
      "Long An",
      "Nam Định",
      "Nghệ An",
      "Ninh Bình",
      "Ninh Thuận",
      "Phú Thọ",
      "Phú Yên",
      "Quảng Bình",
      "Quảng Nam",
      "Quảng Ngãi",
      "Quảng Ninh",
      "Quảng Trị",
      "Sóc Trăng",
      "Sơn La",
      "Tây Ninh",
      "Thái Bình",
      "Thái Nguyên",
      "Thanh Hóa",
      "Thừa Thiên-Huế",
      "Tiền Giang",
      "Trà Vinh",
      "Tuyên Quang",
      "Vĩnh Long",
      "Vĩnh Phúc",
      "Yên Bái"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg",
    "dial": "+84",
    "code": "VN"
  },
  {
    "name": "Yemen",
    "states": [
      "'Adan Governorate",
      "'Amran Governorate",
      "Abyan Governorate",
      "Al Bayda' Governorate",
      "Al Hudaydah Governorate",
      "Al Jawf Governorate",
      "Al Mahrah Governorate",
      "Al Mahwit Governorate",
      "Dhamar Governorate",
      "Hadhramaut Governorate",
      "Hajjah Governorate",
      "Ibb Governorate",
      "Lahij Governorate",
      "Ma'rib Governorate",
      "Raymah Governorate",
      "Saada Governorate",
      "Sana'a",
      "Sana'a Governorate",
      "Shabwah Governorate",
      "Socotra Governorate",
      "Ta'izz Governorate"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg",
    "dial": "+967",
    "code": "YE"
  },
  {
    "name": "Zambia",
    "states": [
      "Central Province",
      "Copperbelt Province",
      "Eastern Province",
      "Luapula Province",
      "Lusaka Province",
      "Muchinga Province",
      "Northern Province",
      "Northwestern Province",
      "Southern Province",
      "Western Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg",
    "dial": "+260",
    "code": "ZM"
  },
  {
    "name": "Zimbabwe",
    "states": [
      "Bulawayo Province",
      "Harare Province",
      "Manicaland",
      "Mashonaland Central Province",
      "Mashonaland East Province",
      "Mashonaland West Province",
      "Masvingo Province",
      "Matabeleland North Province",
      "Matabeleland South Province",
      "Midlands Province"
    ],
    "flag": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg",
    "dial": "+263",
    "code": "ZW"
  }
]
